<template>
    <swiper :slides-per-view="'auto'" @swiper="onSwiper" class="swiper">
        <swiper-slide v-for="(item, index) in data" :key="item.slug" :virtualIndex="index" class="slide">
            <router-link :to="workPath(item.slug)">
                <works-card :item="item"></works-card>
            </router-link>
        </swiper-slide>
        <template v-slot:container-end v-if="!data.length">
            <div class="swiper-wrapper">
                <div class="swiper-slide slide"><works-card></works-card></div>
                <div class="swiper-slide slide"><works-card></works-card></div>
                <div class="swiper-slide slide"><works-card></works-card></div>
            </div>
        </template>
    </swiper>
    <!-- <div class="swiper">
        <div class="swiper-wrapper"></div>
    </div> -->
    <!-- <transition-group name="slide" tag="div" class="works-list">
        <div v-for="item in data" :key="item.slug" class="slide">
            <router-link :to="$route.path + '/' + item.slug">
                <works-card :item="item"></works-card>
            </router-link>
        </div>
    </transition-group> -->
</template>

<script>
import WorksCard from "./WorksCard.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
    data() {
        return {
            swiper: null,
        };
    },
    props: ["data"],
    components: { WorksCard, Swiper, SwiperSlide },
    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },
        onTransitionStart() {
            this.swiper.slideTo(0);
            // console.log(this.swiper);
        },
        onTransitionEnd() {
            this.swiper && this.swiper.update();
        },
        workPath(link) {
            let base = this.$route.path.split("/").filter((i) => i)[0];
            base = base !== "works" && base !== "presentation" ? base : null;

            let path = "/" + [base, "works", link].filter((i) => i).join("/");

            return path;
        },
    },
    mounted() {
        setTimeout(() => {
            console.log(this.$refs.swiper);
        }, 500);
    },
};
</script>

<!-- <style scoped lang="scss">
.works-list {
    display: flex;
    &::before,
    &::after {
        content: "";
        flex: 1;
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: 0.5s;
}
.slide-move {
    transition: all 0.5s ease;
    & > * {
        // transition: inherit;
        transition: all 0.5s ease;
    }
}

.slide-leave-to {
    opacity: 0;
    //max-width: 0;
    transform: translateY(-100%);
}

.slide-move {
    // transform: translateX(0);
}

.slide-enter-to,
.slide-leave-from,
.slide-move {
    max-width: 20.6rem;
    // transform: translateX(0);
    z-index: 2;
}
.slide-enter-from,
.slide-leave-to {
    // transform: translateY(50%);
    max-width: 0;
    & > * {
        // transform: translateX(-50%) translateY(-50%) scale(0);
    }
}

.slide {
    width: fit-content;
    & > * {
        display: block;
        width: 20.6rem;
    }
}
</style> -->

<!-- <style lang="scss" scoped>
.slide {
    width: 26rem;
    transform-origin: left center;
    & > * {
        display: block;
        width: 26rem;
    }
    &:not(:first-child) {
        margin-left: 1.25rem;
    }
}

.slide {
    &-move {
    }
}
.slide-enter {
    &-from {
        transform: scaleX(0);
        opacity: 0.5;
        width: 0px;
        margin-left: 0 !important;
    }
    &-active {
        // transition: 0.25s;
    }
    &-to {
        transform: scaleX(1);
        opacity: 1;
    }
}
.slide-leave {
    &-from {
        transform: scaleX(1);
        opacity: 1;
    }
    &-active {
        // transition: 0.25s;
    }
    &-to {
        transform: scaleX(0);
        opacity: 0.5;
        width: 0px;
        margin-left: 0 !important;
    }
}
</style> -->
<style lang="scss">
.swiper {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.slide {
    width: fit-content;
    & > * {
        display: block;
        width: 20.6rem;
    }
    &:not(:first-child) {
        margin-left: 1.25rem;
    }

    @media (max-width: 979px) {
        & > * {
            display: block;
            width: 15rem;
        }
        &:not(:first-child) {
            margin-left: 0.75rem;
        }
    }
}
</style>

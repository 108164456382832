export default {
    data() {
        return {
            is_mobile: false,
            mediaQuery: null,
        };
    },
    methods: {
        observeDeviceType(callback) {
            this.mediaQuery = this.mediaQuery || window.matchMedia("(max-width: 979px)");

            this.is_mobile = this.mediaQuery.matches;
            this.mediaQuery.addEventListener("change", callback);
        },
    },

    created() {
        this.observeDeviceType((event) => {
            this.is_mobile = event.matches;
            console.log("change type");
        });
    },
};

<template>
    <div class="footer" ref="footer">
        <div class="container">
            <div class="footer__inner">
                <div class="footer__pagination">
                    <slot name="pagination"></slot>
                </div>
                <div class="footer__row-left">
                    <slot name="feedback"></slot>
                </div>
                <div class="footer__row-center">
                    <slot name="box_alt_btn"></slot>
                </div>
                <div class="footer__row-right box">
                    <div class="box__placeholder"></div>
                    <slot name="box"></slot>
                </div>
                <div class="footer__bottom">
                    <slot name="bottom"></slot>
                </div>
                <div class="footer__sizer"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: [],
    methods: {
        calculateSafeArea() {},
    },
    mounted() {
        let sizer = this.$refs.footer.querySelector(".footer__sizer");
        let bottom = this.$refs.footer.querySelector(".footer__bottom");

        let resizeObserver = new ResizeObserver((e) => {
            let sizerRect = sizer.getBoundingClientRect();
            let bottomRect = bottom.getBoundingClientRect();
            document.documentElement.style.setProperty("--footer-safe-area", `${sizerRect.height}px`);
            document.documentElement.style.setProperty("--footer-height", `${bottomRect.height}px`);
        });

        resizeObserver.observe(sizer);
    },
};
</script>
<style lang="scss">
$font14: max(0.875rem, 10px);

.footer {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;

    &__inner {
        display: grid;
        grid-gap: 1rem;

        position: relative;

        & > * {
            pointer-events: all;
        }
    }
    &__pagination {
    }
    &__row-left {
    }
    &__row-center {
    }
    &__row-right {
    }
    &__bottom {
    }
    &__sizer {
        position: absolute;
        width: 100%;
        height: 100%;

        pointer-events: none;
    }
    @media (max-width: 979px) {
        &__inner {
            grid-gap: 3.125rem;
        }
        &__pagination {
            display: none !important;
        }
        &__row-left {
            place-self: center;
            order: 1;
            .btn {
                width: 13rem;
            }
        }
        &__row-right {
            place-self: center;
            order: 2;
        }
        &__row-center {
            place-self: center;
            order: 3;
            .btn {
                width: 13rem;
            }
        }
        &__bottom {
            order: 4;
        }
        &__sizer {
        }
    }
    @media (min-width: 980px) {
        &__inner {
            // grid-gap: 2rem;
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            grid-template-rows: auto 1fr auto auto;

            grid-template-areas:
                ". . pagination"
                ". . right"
                "left center right"
                "bottom bottom bottom";
        }
        &__pagination {
            margin-bottom: 2rem;
            grid-area: pagination;

            justify-self: end;
        }
        &__row-left {
            grid-area: left;
            justify-self: start;
            .btn {
                width: 21.25rem;
            }
        }
        &__row-center {
            grid-area: center;
            .btn {
                width: 19.75rem;
            }
        }
        &__row-right {
            grid-area: right;
            justify-self: end;
        }
        &__bottom {
            margin-top: 2rem;
            grid-area: bottom;
        }
        &__sizer {
            grid-row: 3/-1;
        }
    }
}

.pagination {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    & > a {
        position: relative;
        pointer-events: all;
        span {
            position: absolute;
            white-space: nowrap;
            right: calc(100% + 0.65em);

            line-height: 2.15em;
            padding: 0 0.78em;
            border-radius: calc(2.15em / 2);
            top: 50%;

            transform-origin: right;
            transform: translateY(-50%);

            background: var(--color-yellow);
            transition: 0.25s;
            transition-property: transform, opacity;

            font-size: $font14;
        }
        &::after {
            content: "";
            display: block;
            width: 1rem;
            height: 1rem;

            color: #d1d1d1;
            border-radius: 50%;
            background: currentColor;

            transition: 0.25s;
            transition-property: transform, color;
        }

        &:hover,
        &.router-link-active {
            &::after {
                transform: scale(1.25);
            }
        }
        &.router-link-active {
            &::after {
                color: var(--color-purple);
            }
        }
        &:not(:hover) {
            span {
                transform: translateY(-50%) scale(0.5);
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    [theme="primary"] & {
        & > a {
            &.router-link-active {
                &::after {
                    color: var(--color-yellow);
                }
            }
        }
    }
}
.bottom {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    gap: 2.3em;
    padding: 1.7em 0;

    transition: color 0.25s;

    z-index: 10;

    &__copyright {
        order: -1;
    }
    &::before {
        content: "";
        flex: 1;
        border-bottom: 1px solid currentColor;
        opacity: 0.2;
    }

    [theme="primary"] & {
        color: #fff;
    }

    @media (max-width: 979px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: stretch;
        font-size: 0.56rem;
        gap: 1.5em;
        &::before {
            content: none;
        }
        &__copyright {
            display: flex;
            align-items: center;
            gap: 1.3em;
            &::before,
            &::after {
                content: "";
                flex: 1;
                border-bottom: 1px solid currentColor;
                opacity: 0.2;
            }
        }
    }
}
.socials {
    $self: &;
    display: flex;
    align-items: center;
    gap: 1.925em;
    &__title {
    }
    &__list {
        display: flex;
        gap: inherit;
    }
    &__item {
        transition: color 0.25s;
        color: var(--color-purple);
        svg {
            width: 3.23em;
            height: auto;
        }
    }

    [theme="primary"] & {
        &__item {
            color: var(--color-yellow);
        }
    }
    @media (max-width: 979px) {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 1.5em;
        &__title {
            display: flex;
            align-items: center;
            gap: 1.3em;
            &::before,
            &::after {
                content: "";
                flex: 1;
                border-bottom: 1px solid currentColor;
                opacity: 0.2;
            }
        }
        &__list {
            align-self: center;
        }
    }
}
.box {
    position: relative;
    width: 100%;
    &__placeholder {
        padding: 0.5rem;
        padding-bottom: 2rem;
        &::after {
            content: "";
            display: block;
            width: 100%;
            aspect-ratio: 1920/1080;
        }
    }

    @media (min-width: 980px) {
        width: 23.75rem;
    }
}
</style>

<template>
    <ul class="arrow-list" ref="list">
        <li v-for="str in list" v-bind:key="str" class="arrow-list__item">
            <!-- <svg width="862" height="16" viewBox="0 0 862 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M861.707 8.70711C862.098 8.31658 862.098 7.68342 861.707 7.29289L855.343 0.928932C854.953 0.538408 854.319 0.538408 853.929 0.928932C853.538 1.31946 853.538 1.95262 853.929 2.34315L859.586 8L853.929 13.6569C853.538 14.0474 853.538 14.6805 853.929 15.0711C854.319 15.4616 854.953 15.4616 855.343 15.0711L861.707 8.70711ZM0 9H861V7H0L0 9Z"
                    fill="currentColor"
                />
            </svg> -->
            <!-- <svg width="912" height="9" viewBox="0 0 912 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M912.354 5.35363C912.549 5.15837 912.549 4.84179 912.354 4.64653L909.172 1.46455C908.976 1.26928 908.66 1.26928 908.464 1.46455C908.269 1.65981 908.269 1.97639 908.464 2.17165L911.293 5.00008L908.464 7.82851C908.269 8.02377 908.269 8.34035 908.464 8.53561C908.66 8.73088 908.976 8.73088 909.172 8.53561L912.354 5.35363ZM-4.37114e-08 5.5L912 5.50008L912 4.50008L4.37114e-08 4.5L-4.37114e-08 5.5Z"
                    fill="currentColor"
                />
            </svg> -->
            <svg width="913" height="9" viewBox="0 0 913 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M912.354 5.35363C912.549 5.15837 912.549 4.84179 912.354 4.64653L909.172 1.46455C908.976 1.26928 908.66 1.26928 908.464 1.46455C908.269 1.65981 908.269 1.97639 908.464 2.17165L911.293 5.00008L908.464 7.82851C908.269 8.02377 908.269 8.34035 908.464 8.53561C908.66 8.73088 908.976 8.73088 909.172 8.53561L912.354 5.35363ZM-4.37114e-08 5.5L912 5.50008L912 4.50008L4.37114e-08 4.5L-4.37114e-08 5.5Z"
                    fill="currentColor"
                />
            </svg>

            <span>
                <span class="fade_in">{{ str }}</span>
            </span>
        </li>
    </ul>
</template>

<script>
export default {
    props: ["list"],
    mounted() {
        let { timeline } = this.$gsap;
        let list = this.$refs.list;
        let fade_in = list.querySelectorAll("li .fade_in");
        let arrows = list.querySelectorAll("li svg");

        this.animation = timeline()
            .from(fade_in, {
                opacity: 0,
                duration: 1,
                stagger: 1 / 4,
            })
            .fromTo(
                arrows,
                {
                    x: "-30em",
                },
                {
                    x: "-0.5em",
                    duration: 1,
                    stagger: 1 / 4,
                },
                0.25 / 4
            );
    },
};
</script>

<style lang="scss">
.arrow-list {
    display: flex;
    flex-direction: column;
    gap: 1.2em;

    font-size: 1.875rem;
    font-weight: 300;
    &__item {
        position: relative;
        transition: 0.25s;
        transition-property: color;
        svg {
            position: absolute;
            right: 100%;
            top: 0.25em;
            height: 0.5em;
            width: auto;
            max-width: unset;

            color: var(--color-yellow);
            transform: translateX(-1em);

            transition: inherit;
            transition-property: color, right;
        }
        &:hover {
            color: var(--color-purple);
            svg {
                transition-delay: 0.25s;
                color: var(--color-purple);
                right: calc(100% - 0.35em);
            }
        }
    }

    @media (max-width: 979px) {
        font-size: 1rem;
        padding-left: 3.33em;
    }
}
</style>

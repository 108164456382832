import { gsap } from "gsap";

const actions = {
    xListeners: new Map(),
    yListeners: new Map(),
};
let oldX = 0;
let oldY = 0;
let mouseX = 0;
let mouseY = 0;

document.addEventListener("mousemove", (event) => {
    mouseX = (event.clientX / innerWidth) * 2 - 1;
    mouseY = (event.clientY / innerHeight) * 2 - 1;
});

(function anim() {
    if (oldX !== mouseX) {
        Array.from(actions.xListeners.values()).forEach((item) => {
            item.quick(~~(item.mult * mouseX), 0, true);
        });
        oldX = mouseX;
    }
    if (oldY !== mouseY) {
        Array.from(actions.yListeners.values()).forEach((item) => {
            item.quick(~~(item.mult * mouseY), 0, true);
        });
        oldY = mouseY;
    }

    setTimeout(anim, 100);
})();

export default [
    "mouse-parallax",
    {
        mounted(el, binding) {
            if (!binding.arg) {
                let options = binding.value;
                let { mult } = binding.value;
                delete options.mult;

                let wrapSpan = document.createElement("span");
                gsap.set(wrapSpan, { display: "block" });

                // let isInited = false;
                // el.addEventListener("load", () => {
                // if (isInited) return;
                el.replaceWith(wrapSpan);
                wrapSpan.appendChild(el);
                // isInited = true;
                // });
                // setTimeout(() => {
                // }, 100);

                let xQuick = gsap.quickTo(wrapSpan, "x", options);
                let yQuick = gsap.quickTo(wrapSpan, "y", options);

                actions.xListeners.set(el, {
                    quick: xQuick,
                    mult,
                });
                actions.yListeners.set(el, {
                    quick: yQuick,
                    mult,
                });
            }
        },
        unmounted(el) {
            actions.xListeners.delete(el);
            actions.yListeners.delete(el);
        },
    },
];

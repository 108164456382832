<template>
    <div v-if="success" class="success">
        <!-- <h3 class="section__title">СПАСИБО!</h3> -->
        <div class="main-form-title" ref="formTitle">
            <svg width="862" height="16" viewBox="0 0 862 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M861.707 8.70711C862.098 8.31658 862.098 7.68342 861.707 7.29289L855.343 0.928932C854.953 0.538408 854.319 0.538408 853.929 0.928932C853.538 1.31946 853.538 1.95262 853.929 2.34315L859.586 8L853.929 13.6569C853.538 14.0474 853.538 14.6805 853.929 15.0711C854.319 15.4616 854.953 15.4616 855.343 15.0711L861.707 8.70711ZM0 9H861V7H0L0 9Z"
                    fill="currentColor"
                /></svg
            ><span>
                ВАШЕ СООБЩЕНИЕ ОТПРАВЛЕНО!
                <br />
                <i> В БЛИЖАЙШЕЕ ВРЕМЯ МЫ СВЯЖЕМСЯ С ВАМИ </i>
            </span>
        </div>
        <router-link to="/" class="btn" data-btn-text="НА ГЛАВНУЮ СТРАНИЦУ"></router-link>
    </div>
    <div v-else>
        <div class="main-form-title" ref="formTitle">
            <svg width="862" height="16" viewBox="0 0 862 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M861.707 8.70711C862.098 8.31658 862.098 7.68342 861.707 7.29289L855.343 0.928932C854.953 0.538408 854.319 0.538408 853.929 0.928932C853.538 1.31946 853.538 1.95262 853.929 2.34315L859.586 8L853.929 13.6569C853.538 14.0474 853.538 14.6805 853.929 15.0711C854.319 15.4616 854.953 15.4616 855.343 15.0711L861.707 8.70711ZM0 9H861V7H0L0 9Z"
                    fill="currentColor"
                /></svg
            ><span>Будем очень рады общению с Вами! </span>
        </div>
        <form class="form" v-on:submit.prevent="createLead" enctype="multipart/form-data">
            <input name="email" type="hidden" v-model="email" />
            <input class="input" type="text" name="name" placeholder="Имя" v-model="name" required />
            <input class="input" name="telephone" v-mask="'+7 ### ###-##-##'" type="text" placeholder="Телефон" v-model="telephone" required />
            <input class="input" name="mail" type="email" placeholder="E-mail" v-model="mail" />
            <!-- <label class="input-file"
                    ><input type="file" ref="fileInput"  @change="onFileChange" style="opacity: 0; position: absolute; top: 0; left: 0; width: 1px; height: 1px" /><span class="input-file__text">{{ fileName || "ДОБАВИТЬ ФАЙЛ" }}</span>
                </label> -->
            <textarea class="input" name="comment" v-model="comment" placeholder="Ваше сообщение"></textarea>
            <button class="btn" data-btn-text="ОТПРАВИТЬ"></button>
        </form>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            success: false,

            telephone: "",
            name: "",
            email: "",
            mail: "",
            comment: "",
            fileName: null,
        };
    },
    methods: {
        createLead() {
            axios({
                method: "POST",
                url: "/api/form",
                params: {
                    name: this.name,
                    telephone: this.telephone,
                    email: this.email,
                    mail: this.mail,
                    comment: this.comment,
                },
            }).then((response) => {
                console.log(response);
                this.name = "";
                this.telephone = "";
                this.email = "";
                this.comment = "";
                this.mail = "";
                this.success = true;
                this.$emit("formSent");
            });
        },
        onFileChange() {
            if (event.target.files[0]) {
                this.fileName = event.target.files[0].name;
            } else {
                this.fileName = null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.success {
    .btn {
        margin-top: 3em;
        width: fit-content;
    }
}
.section__title {
    margin-bottom: 1em;
}
.main-form-title {
    text-transform: uppercase;
    position: relative;
    font-weight: 600;
    line-height: 1.3;

    margin-bottom: 0.8em;

    transition: 0.25s;
    transition-property: color;

    i {
        font-weight: 300;
    }

    svg {
        position: absolute;
        right: calc(100% + 0.4em);
        top: 0.35em;
        height: 0.5em;
        width: auto;

        color: var(--color-yellow);
    }

    &:hover {
        color: var(--color-purple);
    }

    @media (max-width: 979px) {
        margin-left: 2em;
    }

    @media (min-width: 980px) {
        font-size: 1.875rem;
    }
}
.form {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 1fr;
}
textarea.input {
    grid-column: 1/-1;
    resize: none;
}
.btn {
    grid-column: 1/-1;
    width: 100%;
    max-width: 21.25rem;
}

@media (max-width: 979px) {
    .form {
        grid-template-columns: unset;
        grid-template-rows: unset;
    }
    textarea.input,
    .btn {
        grid-column: unset;
        max-width: unset;
    }
}
</style>

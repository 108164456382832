<template>
	<FullscreenSection class="home" ref="content">
		<template v-slot:title>
			<SectionTitle class="home__title" ref="title">
				<div class="home__title-first-line">
					<div class="home__title-first-line-colored">
						<div class="home__title-first-line-colored-bg" ref="colored_bg"></div>
						<div class="hide_overflow" style="display: inline-block">
							<span class="slide_up">
								GOOD
								<span class="home__title-hover-white">ADS</span>
							</span>
						</div>
					</div>
					<div class="hide_overflow">
						<span ref="dash">—</span>
					</div>
				</div>
				<div class="hide_overflow"><i class="slide_up">DIGITAL</i></div>
				<div class="hide_overflow"><i class="slide_up">PERFORMANCE</i></div>
				<div class="hide_overflow"><i class="slide_up">АГЕНТСТВО</i></div>
				<div class="hide_overflow"><i class="slide_up">ПОЛНОГО ЦИКЛА</i></div>
			</SectionTitle>
		</template>

		<template v-slot:content>
			<ArrowList :list="list" ref="list"></ArrowList>
		</template>

		<template v-slot:assets>
			<div class="home__assets" ref="assets">
				<div class="home__assets-box">
					<img class="star" v-lazy="{ src: '/assets/home/star.png', loading: '/assets/home/resized/star.png' }" alt="" v-mouse-parallax="{ mult: 20, duration: 0.4 * 1.3 ** 4, ease: 'power2.out' }" />
					<img class="star" v-lazy="{ src: '/assets/home/star.png', loading: '/assets/home/resized/star.png' }" alt="" v-mouse-parallax="{ mult: 20, duration: 0.4 * 1.3 ** 3, ease: 'power2.out' }" />
					<img class="star" v-lazy="{ src: '/assets/home/star.png', loading: '/assets/home/resized/star.png' }" alt="" v-mouse-parallax="{ mult: 20, duration: 0.4 * 1.3 ** 2, ease: 'power2.out' }" />
					<img class="star" v-lazy="{ src: '/assets/home/star.png', loading: '/assets/home/resized/star.png' }" alt="" v-mouse-parallax="{ mult: 20, duration: 0.4 * 1.3 ** 1, ease: 'power2.out' }" />
					<AnimatedComponent :to="{ scale: 1.1 }" :progress="mouseToHandDist">
						<img ref="hand" class="hand" v-lazy="{ src: '/assets/home/hand.png', loading: '/assets/home/resized/hand.png' }" alt="" v-mouse-parallax="{ mult: 20, duration: 0.4 * 1.3 ** 2, ease: 'power2.out' }" />
					</AnimatedComponent>
				</div>
			</div>
		</template>
	</FullscreenSection>
</template>

<script>
	import FullscreenSection from "@/components/FullscreenSection.vue";
	import SectionTitle from "@/components/SectionTitle.vue";
	import ArrowList from "@/components/ArrowList.vue";
	import AnimatedComponent from "@/components/AnimatedComponent.vue";

	export default {
		data() {
			return {
				list: [
					//
					"Лучшие решения для роста вашего бизнеса!",
					"Качество по разумной цене и в приятно удивляющие сроки!",
					"Станьте лидером рынка уже после 3х месяцев сотрудничества с нами!",
				],
				mouseToHandDist: 1,
			};
		},
		components: {
			FullscreenSection,
			SectionTitle,
			ArrowList,
			AnimatedComponent,
		},
		methods: {
			createAssetsAnimation() {
				let { timeline } = this.$gsap;
				let assets = this.$refs.assets;
				let stars = assets.querySelectorAll(".star");
				let hand = assets.querySelectorAll(".hand");

				return (
					timeline()
						//
						.from(stars, {
							scale: 0.5,
							duration: 1,
							stagger: 0.125,
						})
						.from(
							stars,
							{
								opacity: 0,
								duration: 0.5,
								stagger: 0.125,
							},
							"<0%"
						)
						.from(
							hand,
							{
								scale: 0.95,
								x: "5%",
								y: "5%",
								duration: 1.25,
								ease: "power2.out",
							},
							"<0%"
						)
						.from(
							hand,
							{
								opacity: 0,
								duration: 0.5,
							},
							"<0%"
						)
					// .eventCallback("onComplete", function () {
					//     this.clear();
					// })
				);
			},
			watchMouseToHandDist(e) {
				let hand = this.$refs.hand;
				let handRect = hand.getBoundingClientRect();
				this.distToHandSetter = this.distToHandSetter || this.$gsap.quickTo(this, "mouseToHandDist", { duration: 1, ease: "power1.out" });
				let cx = handRect.x + handRect.width / 8;
				let cy = handRect.y + handRect.height / 4;
				let mx = e.clientX;
				let my = e.clientY;

				let dist = Math.hypot(mx - cx, my - cy);
				this.distToHandSetter((1 - dist / Math.max(innerWidth, innerHeight)) ** 4);
			},
		},
		mounted() {
			const { timeline } = this.$gsap;
			let title_timeline = timeline()
				.delay(0.2)
				.add(this.$refs.title.animation)
				.add(
					timeline()
						.from(this.$refs.dash, {
							x: "-1em",
							duration: 0.35,
						})
						.from(
							this.$refs.colored_bg,
							{
								x: "-27em",
								duration: 1.5,
							},
							"<-=50%%"
						),
					"<0%"
				);

			this.$refs.content.animation
				//
				.add(this.createAssetsAnimation())
				.add(title_timeline, "<12%")
				.add(this.$refs.list.animation);

			window.addEventListener("mousemove", this.watchMouseToHandDist);
		},
		unmounted() {
			window.removeEventListener("mousemove", this.watchMouseToHandDist);
		},
	};
</script>

<style lang="scss" scoped>
	.section {
		$self: &;
		&__inner {
			& > *:not(#{$self}__assets) {
				max-width: 54rem;
			}
		}
	}
</style>

<style lang="scss">
	.home {
		$self: &;
		&__title {
			i {
				font-weight: 300;
			}
			&-first-line {
				display: inline-flex;
				align-items: center;
				gap: 0.35em;
				& > div > span {
					position: relative;
					display: inline-block;
					top: -0.175em;
				}
				&-colored {
					position: relative;
					transition: color 0.25s;
					&-bg {
						display: block;
						position: absolute;
						top: -0.1em;
						left: -100vw;
						right: -0.2em;
						bottom: 0.1em;

						background: var(--color-yellow);
						transition: background-color 0.25s;
					}
				}
			}
			&-hover-white {
				color: var(--color-purple);
				transition: color 0.25s;
			}
			&:hover {
				#{$self}__title-first-line {
					&-colored {
						color: var(--color-yellow);
						&-bg {
							background: var(--color-purple);
						}
					}
				}
				#{$self}__title-hover-white {
					color: white;
				}
			}
		}

		.arrow-list {
			@media (max-width: 979px) {
				&__item {
					&:nth-child(1) {
						width: 9.5em;
					}
					&:nth-child(2) {
						width: 12.5em;
					}
					&:nth-child(3) {
						width: 14.5em;
					}
				}
			}
		}
	}
	.home {
		&__assets {
			pointer-events: none;

			width: 77rem;
			max-width: 100% !important;
			margin: 0 !important;

			&-box {
				position: relative;
				width: 100%;
				padding-bottom: 76%;

				& > * {
					position: absolute;
					max-width: unset;
					&:nth-child(1) {
						width: 6%;
						top: 34%;
						left: 0.5%;
					}
					&:nth-child(2) {
						width: 6%;
						top: 34%;
						left: 8%;
					}
					&:nth-child(3) {
						width: 6%;
						top: 34%;
						left: 15.5%;
					}
					&:nth-child(4) {
						width: 6%;
						top: 34%;
						left: 23.5%;
					}
					&:nth-child(5) {
						width: 115%;
						top: -19%;
						left: 23%;
					}
				}
			}
		}
		@media (min-width: 980px) {
			&__assets {
				position: absolute;
				left: 50%;
				transform: translateX(-17rem) translateY(-28rem);
			}
		}
		@media (max-width: 979px) {
			&__assets {
				width: 18rem;
				&-box {
					margin-top: 5%;
					margin-bottom: -35%;
					& > * {
						&:nth-child(1) {
							width: 9%;
							left: -1.2%;
						}
						&:nth-child(2) {
							width: 9%;
							left: 8.7%;
						}
						&:nth-child(3) {
							width: 9%;
							left: 18.9%;
						}
						&:nth-child(4) {
							width: 9%;
							left: 29.3%;
						}
						&:nth-child(5) {
							width: 138%;
							left: 19%;
							top: -17%;
							transform: rotate(12deg);
						}
					}
				}
			}
		}
	}
</style>

<template>
    <FullscreenSection class="contacts feedback" data-section-theme="" ref="screen">
        <Close></Close>
        <template v-slot:title>
            <SectionTitle :content="['СВЯЗАТЬСЯ С НАМИ']"></SectionTitle>
        </template>
        <template v-slot:content>
            <div class="contacts__form-place">
                <div class="contacts__form-title" ref="formTitle">
                    <svg width="862" height="16" viewBox="0 0 862 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M861.707 8.70711C862.098 8.31658 862.098 7.68342 861.707 7.29289L855.343 0.928932C854.953 0.538408 854.319 0.538408 853.929 0.928932C853.538 1.31946 853.538 1.95262 853.929 2.34315L859.586 8L853.929 13.6569C853.538 14.0474 853.538 14.6805 853.929 15.0711C854.319 15.4616 854.953 15.4616 855.343 15.0711L861.707 8.70711ZM0 9H861V7H0L0 9Z"
                            fill="currentColor"
                        /></svg
                    ><span>Будем очень рады общению с Вами! </span>
                </div>
                <MainForm class="contacts__form" ref="form"></MainForm>
            </div>
        </template>
        <template v-slot:assets>
            <div class="feedback__assets" ref="assets">
                <div class="feedback__assets-box">
                    <img
                        class="left_hand"
                        v-lazy="{ src: '/assets/feedback/left_hand.png', loading: '/assets/feedback/resized/left_hand.png' }"
                        alt=""
                        v-mouse-parallax="{ mult: 25, duration: 1.25, ease: 'power2.out' }"
                    />
                    <img
                        class="right_hand"
                        v-lazy="{ src: '/assets/feedback/right_hand.png', loading: '/assets/feedback/resized/right_hand.png' }"
                        alt=""
                        v-mouse-parallax="{ mult: -10, duration: 0.5, ease: 'power2.out' }"
                    />
                </div>
            </div>
        </template>
    </FullscreenSection>
</template>

<script>
import FullscreenSection from "@/components/FullscreenSection.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import MainForm from "@/components/MainForm.vue";
import Close from "@/components/Close.vue";

export default {
    data() {
        return {
            fileName: null,
        };
    },
    components: {
        FullscreenSection,
        SectionTitle,
        MainForm,
        Close,
    },
    methods: {
        createAssetsAnimation() {
            let { timeline } = this.$gsap;
            let assets = this.$refs.assets;
            let left_hand = assets.querySelectorAll(".left_hand");
            let right_hand = assets.querySelectorAll(".right_hand");

            return (
                timeline()
                    //
                    .from(assets, {
                        opacity: 0,
                        duration: 0.75,
                    })
                    .from(
                        left_hand,
                        {
                            y: "-100%",
                            x: "-100%",
                            duration: 2.5,
                            ease: "elastic.out(0.6, 1)",
                        },
                        "<0%"
                    )
                    .from(
                        right_hand,
                        {
                            y: "-100%",
                            x: "100%",
                            duration: 2.5,
                            ease: "elastic.out(0.6, 1)",
                        },
                        "<0%"
                    )
            );
        },
    },
    mounted() {
        this.createAssetsAnimation();
        // document.body.style.overflow = "hidden";
    },
    unmounted() {
        // this.$emit("onContentMounted");
        // document.body.style.overflow = "";
    },
};
</script>

<style lang="scss" scoped>
.section {
    padding-bottom: 8rem;
    $self: &;
    &__inner {
        & > *:not(#{$self}__assets) {
            max-width: 51.25rem;
        }
    }

    @media (max-width: 979px) {
        padding-bottom: 4rem !important;
        &__title {
            margin-top: 0 !important;
        }
    }
}
.contacts {
    $self: &;
    &__title {
        color: #000;
    }
    &__form-title {
        font-size: 1.875rem;
        text-transform: uppercase;
        font-weight: 600;

        margin-bottom: max(0.86em, 1rem);

        position: relative;
        transition: 0.25s;
        transition-property: color;
        svg {
            position: absolute;
            right: 100%;
            top: 0.05em;
            height: 0.85em;
            width: auto;

            color: var(--color-purple);
            transform: translateX(-1em);

            transition: 0.25s;
            transition-property: color, right;
        }

        &:hover {
            color: var(--color-purple);
            svg {
                color: var(--color-yellow);
                right: calc(100% - 0.75em);
            }
        }
    }

    .btn {
        grid-column: span 2;
        margin-top: 2rem;
    }
    .input,
    textarea,
    .input-file {
        font-size: max(1em, 16px);
    }

    @media (max-width: 979px) {
        &__form-title {
            font-size: 1rem;
            margin-left: 3.33em;
        }
        // &__form {
        //     grid-template-columns: unset;
        //     grid-template-rows: unset;
        //     grid-gap: 0.625rem 1.25rem;
        //     min-height: unset;
        //     .btn {
        //         grid-column: unset;
        //         margin-top: unset;
        //     }
        //     textarea {
        //         grid-column: unset;
        //     }
        // }
    }
}

.feedback {
    position: fixed !important;
    top: 0;
    z-index: 10;
}
</style>

<!-- <style lang="scss">
.feedback {
    max-height: 100vh;
    max-width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    &__assets {
        pointer-events: none;

        width: 60.75rem;
        max-width: 100% !important;
        margin: 0 !important;

        img {
            width: 100%;
        }
        &-box {
            position: relative;
            width: 100%;
            padding-bottom: 83%;

            & > * {
                position: absolute;
                max-width: unset;
                &:nth-child(1) {
                    width: 158%;
                    top: -99%;
                    left: -85%;
                    z-index: 2;
                }
                &:nth-child(2) {
                    width: 158%;
                    top: -48%;
                    left: 34%;
                    z-index: 1;
                }
            }
        }
    }
    @media (min-width: 980px) {
        &__assets {
            position: absolute;
            left: 50%;
            transform: translateX(-2.75rem) translateY(-18rem);
        }
    }
    @media (max-width: 979px) {
        &__assets {
            width: 18rem;
            &-box {
                transform: rotate(-12deg) translateX(-10%);
                margin-top: -8%;
                margin-bottom: -12%;
            }
        }
    }
}
</style> -->

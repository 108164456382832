<template>
    <Logo color="#fff" background="var(--color-purple)" id="logo_main" ref="logo_main" />
    <Logo color="var(--color-purple)" background="#fff" id="logo_alt" ref="logo_alt" />
</template>

<script>
import Logo from "@/assets/logo.vue";

export default {
    data() {
        return {
            last_theme: null,
        };
    },
    components: {
        Logo,
    },
    methods: {
        start(theme) {
            let [actual_logo, alternate_logo] = [this.$refs.logo_main, this.$refs.logo_alt].sort(() => (theme !== "primary" ? 1 : -1));

            alternate_logo.hide().progress(0);
            this.current_animation = actual_logo.start();

            this.last_theme = theme;
            return this.current_animation;
        },
        change(theme) {
            this.current_animation && this.current_animation.progress(100).pause();

            let actual_logo = theme !== "primary" ? this.$refs.logo_main : this.$refs.logo_alt;
            let alternate_logo = this.last_theme !== "primary" ? this.$refs.logo_main : this.$refs.logo_alt;

            if (theme === this.last_theme) {
                this.current_animation = this.$gsap.timeline().add(actual_logo.change()).duration(3);
            } else {
                this.current_animation = this.$gsap.timeline().add(alternate_logo.hide()).add(actual_logo.show()).duration(2.25);
            }

            this.last_theme = theme;
            return this.current_animation;
        },
        saveLogoPos() {
            let logo = document.querySelector(".logo .logo__background");
            let { width, height, x, y } = logo.getBoundingClientRect();

            let cx = x + width / 2;
            let cy = y + height / 2;

            this.$store.commit("set_logo_pos", {
                cx,
                cy,
            });

            return { cx, cy };
        },
    },
    mounted() {
        this.saveLogoPos();
        window.addEventListener("resize", this.saveLogoPos);
    },
    unmounted() {
        window.removeEventListener("resize", this.saveLogoPos);
    },
};
</script>

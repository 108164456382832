import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import gsapPlugin from "./plugins/gsapPlugin";
// import routingDepthPlugin from "./plugins/routingDepthPlugin";

import VueLazyLoad from "vue3-lazyload";
import { vMask } from "@bardoui/vmask";

import mouseParallax from "./plugins/mouseParallax";

createApp(App)
    .use(store)
    .use(router)
    .use(gsapPlugin)
    // .use(routingDepthPlugin)
    .use(VueLazyLoad)
    .directive("mask", vMask)
    .directive(...mouseParallax)

    .mount("#app");

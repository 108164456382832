<template>
    <!-- header -->
    <div class="header">
        <div class="container">
            <div class="header__inner">
                <div class="header__logo">
                    <router-link to="/"><PageLogo ref="logo" /></router-link>
                </div>
                <transition name="fade-up">
                    <nav class="header__nav" ref="nav" v-show="!is_mobile && !hide_navigation">
                        <router-link to="/about">О КОМПАНИИ</router-link>
                        <router-link to="/advertising">РЕКЛАМА</router-link>
                        <router-link to="/reputation-management">РАБОТА С РЕПУТАЦИЕЙ</router-link>
                        <router-link to="/web-development">ВЕБ-РАЗРАБОТКА</router-link>
                        <router-link to="/consulting">КОНСАЛТИНГ</router-link>
                    </nav>
                </transition>
                <transition name="fade-up">
                    <div class="header__nav" ref="nav_contacts" v-show="!is_mobile && !hide_navigation">
                        <router-link to="/contacts">КОНТАКТЫ</router-link>
                    </div>
                </transition>

                <!-- mobile menu -->
                <div class="header__mobile" v-show="is_mobile">
                    <transition name="fade">
                        <span class="header__mobile_nav_toggle" @click="is_menu_shown = !is_menu_shown" :class="is_menu_shown ? 'active' : ''" v-show="box_initial_state == box_state">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </transition>
                </div>
            </div>
        </div>
    </div>

    <template v-if="is_mobile">
        <transition name="fade-up">
            <template v-if="is_menu_shown">
                <div class="mobile-menu" theme="white">
                    <!-- <span class="close" @click="is_menu_shown = false"></span> -->
                    <div class="container">
                        <div class="mobile-menu__logo">
                            <router-link to="/"><Logo color="var(--color-purple)" background="transparent" id="logo_mobile" ref="logo_mobile" /></router-link>
                        </div>
                        <div class="mobile-menu__list">
                            <router-link to="/about">О КОМПАНИИ</router-link>
                            <router-link to="/advertising">РЕКЛАМА</router-link>
                            <router-link to="/reputation-management">РАБОТА С РЕПУТАЦИЕЙ</router-link>
                            <router-link to="/web-development">ВЕБ-РАЗРАБОТКА</router-link>
                            <router-link to="/consulting">КОНСАЛТИНГ</router-link>
                            <router-link to="/contacts">КОНТАКТЫ</router-link>
                        </div>
                        <div class="mobile-menu__feedback">
                            <router-link to="/feedback" class="btn" data-btn-text="СВЯЗАТЬСЯ С НАМИ"></router-link>
                        </div>
                        <div class="mobile-menu__footer">
                            <div class="socials" v-if="$store.state.contacts.socials.length">
                                <div class="socials__title">Следите за нами в соцсетях:</div>
                                <ul class="socials__list">
                                    <li class="socials__item" v-for="item in $store.state.contacts.socials" :key="item.link">
                                        <a :href="item.link" target="_blank" rel="noopener noreferrer">
                                            <component :is="item.template" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </transition>
    </template>

    <!-- page -->
    <router-view @onContentMounted="contentMounted" v-slot="{ Component }">
        <transition :css="false" @leave="onPageLeave">
            <component :is="Component" ref="content" />
        </transition>
    </router-view>

    <app-footer>
        <template v-slot:pagination>
            <transition-group name="fade-right">
                <div class="pagination" ref="pagination">
                    <router-link to="/" v-if="!hide_pagination"><span>ГЛАВНАЯ</span></router-link>
                    <router-link to="/about" v-if="!hide_pagination"><span>О КОМПАНИИ</span></router-link>
                    <router-link to="/advertising" v-if="!hide_pagination"><span>РЕКЛАМА</span></router-link>
                    <router-link to="/reputation-management" v-if="!hide_pagination"><span>РАБОТА С РЕПУТАЦИЕЙ</span></router-link>
                    <router-link to="/web-development" v-if="!hide_pagination"><span>ВЕБ-РАЗРАБОТКА</span></router-link>
                    <router-link to="/consulting" v-if="!hide_pagination"><span>КОНСАЛТИНГ</span></router-link>
                    <router-link to="/contacts" v-if="!hide_pagination"><span>КОНТАКТЫ</span></router-link>
                </div>
            </transition-group>
        </template>
        <template v-slot:feedback>
            <transition name="fade-down">
                <div v-show="is_mobile || !hide_feedback" ref="feedback_btn">
                    <router-link to="/feedback" class="btn feedback-btn" data-btn-text="СВЯЗАТЬСЯ С НАМИ"></router-link>
                </div>
            </transition>
        </template>
        <template v-slot:box_alt_btn>
            <transition name="fade-down">
                <div v-show="is_mobile || !hide_presentation" ref="presentation_btn">
                    <transition-group name="fade">
                        <button class="btn btn--outline" v-if="box_initial_state === 'presentation-mini'" @click="goTo('works')">
                            <svg width="63" height="63" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                                <use xlink:href="#img-ico-works"></use>
                            </svg>
                            ПРИМЕРЫ РАБОТ
                        </button>
                        <button class="btn btn--outline" v-else @click="goTo('presentation')">
                            <svg width="63" height="63" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                                <use xlink:href="#img-ico-presentation"></use>
                            </svg>
                            ПРЕЗЕНТАЦИЯ
                        </button>
                    </transition-group>
                </div>
            </transition>
        </template>
        <template v-slot:box>
            <transition name="fade-down">
                <div ref="presentation">
                    <PresentationBox :state="box_state" v-show="is_mobile || !hide_presentation"></PresentationBox>
                </div>
            </transition>
        </template>
        <template v-slot:bottom>
            <div class="bottom" ref="footer">
                <span class="bottom__copyright" v-if="$store.state.contacts.copyright" v-text="$store.state.contacts.copyright"></span>
                <div class="bottom__socials socials" v-if="$store.state.contacts.socials.length">
                    <div class="socials__title">Следите за нами в соцсетях:</div>
                    <ul class="socials__list">
                        <li class="socials__item" v-for="item in $store.state.contacts.socials" :key="item.link">
                            <a :href="item.link" target="_blank" rel="noopener noreferrer">
                                <component :is="item.template" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </app-footer>
</template>

<script>
import PageLogo from "@/components/PageLogo.vue";
import FeedbackPopup from "@/views/FeedbackPopup.vue";
import PresentationBox from "@/views/PresentationBox.vue";
import AppFooter from "@/components/AppFooter.vue";
import observeDeviceType from "@/plugins/observeDeviceType";
import Logo from "@/assets/logo.vue";

export default {
    data() {
        return {
            is_content_mounted: false,
            is_menu_shown: false,
        };
    },
    mixins: [observeDeviceType],
    watch: {
        $route() {
            this.is_menu_shown = false;
        },
    },
    computed: {
        hide_navigation() {
            return this.$route.meta.hide_navigation;
        },
        hide_pagination() {
            return this.$route.meta.hide_pagination;
        },
        hide_feedback() {
            return this.$route.meta.hide_feedback;
        },
        hide_presentation() {
            return this.$route.meta.hide_presentation;
        },
        box_initial_state() {
            return this.$route.meta.box_state;
        },
        box_state() {
            return (this.$route.params.work_id && "works-detail") || this.$route.params.box_state || this.$route.meta.box_state;
        },
    },
    components: {
        PageLogo,
        FeedbackPopup,
        PresentationBox,
        AppFooter,
        Logo,
    },
    methods: {
        goTo(box_state = "", work_id) {
            // console.log(this.$route);
            if (this.$route.params.box_state) {
                this.$router.push({
                    params: {
                        box_state,
                        work_id,
                    },
                });
            } else {
                let target_route = this.$route.path
                    //
                    .split("/")
                    .filter((i) => i);
                //
                if (this.is_active) {
                    target_route = target_route.slice(0, -1);
                }
                target_route
                    //
                    .push(box_state);
                target_route = "/" + target_route.join("/");
                console.log(target_route);
                this.$router.push(target_route);
            }
        },
        contentMounted(content_animation = this.$gsap.timeline(), contentChangeMethod = () => this.$gsap.timeline()) {
            const { timeline, from } = this.$gsap;
            const timescale = 2;

            if (this.$route.hash === "#feedback_form") {
                this.$route.meta.theme = "";
            }

            // document.title = SITE_NAME + (this.$route.meta.title ? " - " + upperFirst(this.$route.meta.title) : "");
            // document.body.setAttribute("theme", this.$route.meta.theme);

            if (!this.is_content_mounted) {
                this.is_content_mounted = true;

                let nav_links = [this.$refs.nav.querySelectorAll("a"), this.$refs.nav_contacts.querySelectorAll("a")];
                let nav_animation = from(nav_links, {
                    y: "-3em",
                    opacity: 0,
                    duration: 1,
                    stagger: 0.25,
                });

                // content_animation
                // console.log(content_animation);

                // let pagination_links = this.$refs.pagination && this.$refs.pagination.querySelectorAll("a");
                // let pagination_animation = from(pagination_links, {
                //     x: "1em",
                //     opacity: 0,
                //     duration: 0.5,
                //     stagger: 0.125,
                // });

                // let feedback_btn = this.$refs.feedback_btn;
                // let feedback_btn_animation = from(feedback_btn, {
                //     y: "3em",
                //     opacity: 0,
                //     duration: 1,
                // });

                // let presentation_btn = this.$refs.presentation_btn;
                // let presentation_btn_animation = from(presentation_btn, {
                //     y: "3em",
                //     opacity: 0,
                //     duration: 1,
                // });

                // let presentation = this.$refs.presentation;
                // let presentation_animation = from(presentation, {
                //     y: "3em",
                //     opacity: 0,
                //     duration: 1,
                // });

                // let presentation = this.$refs.presentation;
                // let presentation_animation = presentation && presentation.startAnimation();

                // let footer = this.$refs.footer;
                // let footer_animation = from(footer, {
                //     y: "40%",
                //     opacity: 0,
                //     duration: 1,
                // });

                timeline()
                    .add(nav_animation)
                    .add(this.$refs.logo.start(this.$route.meta.theme), "<0%")
                    .add(content_animation, "<25%")
                    // .add(pagination_animation, "<+=1")
                    // .add(feedback_btn_animation, "<95%")
                    // .add(presentation_btn_animation, "<35%")
                    // .add(presentation_animation, "<35%")
                    // .add(footer_animation, "<35%")

                    .timeScale(timescale);
            } else {
                let logo_animation = this.$refs.logo.change(this.$route.meta.theme);
                let mask_animation = !this.$route.hash ? contentChangeMethod(this.last_theme).eventCallback("onComplete", this.leave_done_function) : null;
                timeline()
                    .add(mask_animation)
                    .add(logo_animation, "<10%")
                    .add(!this.$route.hash ? content_animation : null, "<25%")

                    .timeScale(timescale);
            }

            if (this.last_theme !== this.$route.meta.theme) {
                this.navThemeChange(this.$route.meta.theme);
            }

            this.last_theme = this.$route.meta.theme;
        },
        navThemeChange(theme) {
            let { to } = this.$gsap;
            let nav_links = [this.$refs.nav.querySelectorAll("a"), this.$refs.nav_contacts.querySelectorAll("a")];
            this.nav_theme_animation =
                this.nav_theme_animation ||
                to(nav_links, {
                    color: "#fff",
                    duration: 0.2,
                    stagger: 0.1,
                });
            if (theme === "primary") {
                return this.nav_theme_animation.play(0);
            } else {
                return this.nav_theme_animation.reverse(0);
            }
        },
        onPageLeave(el, done) {
            this.leave_done_function = done;
        },
        // onPopupEnter(el, done) {
        //     const timescale = 3;
        //     let popup = this.$refs.feedback;
        //     popup.animation = popup.$refs.screen.purpleToWhite().timeScale(timescale);
        //     let logo = this.$refs.logo;
        //     logo.change("").timeScale(timescale);
        // },
        // onPopupLeave(el, done) {
        //     const timescale = 3;
        //     let popup = this.$refs.feedback;
        //     popup.animation.reverse().eventCallback("onReverseComplete", done);
        //     let logo = this.$refs.logo;
        //     logo.change(this.$route.meta.theme).timeScale(timescale);
        // },
    },
    mounted() {},
    unmounted() {},
};
</script>

<style lang="scss">
@import "reset-css";
@import "@/scss/base.scss";
</style>
<style lang="scss" scoped>
.fade {
    &-leave-active,
    &-enter-active {
        transition: all 0.7s;
    }
    &-enter-from,
    &-leave-to {
        opacity: 0;
        position: absolute;
    }
    &-enter-to,
    &-leave-from {
        opacity: 1;
    }
}
.fade-down {
    &-leave-active,
    &-enter-active {
        transition: all 0.7s;
    }
    &-enter-from,
    &-leave-to {
        opacity: 0;
        transform: translateY(3rem);
    }
    &-enter-to,
    &-leave-from {
        opacity: 1;
        transform: translateY(0%);
    }
}
.fade-up {
    &-leave-active,
    &-enter-active {
        transition: all 0.7s;
    }
    &-enter-from,
    &-leave-to {
        opacity: 0;
        transform: translateY(-3rem);
    }
    &-enter-to,
    &-leave-from {
        opacity: 1;
        transform: translateY(0%);
    }
}
.fade-left {
    &-leave-active,
    &-enter-active {
        transition: all 0.7s;
    }
    &-enter-from,
    &-leave-to {
        opacity: 0;
        transform: translateX(-3rem);
    }
    &-enter-to,
    &-leave-from {
        opacity: 1;
        transform: translateY(0%);
    }
}
.fade-right {
    &-leave-active,
    &-enter-active {
        transition: all 0.7s;
    }
    &-enter-from,
    &-leave-to {
        opacity: 0;
        transform: translateX(3rem);
    }
    &-enter-to,
    &-leave-from {
        opacity: 1;
        transform: translateY(0%);
    }
}
$font14: max(0.875rem, 10px);

.slide_down-enter-active,
.slide_down-leave-active {
    transition: 0.5s;
    transition-property: opacity, transform;
}
.slide_down-enter-from,
.slide_down-leave-to {
    transform: translateY(3em);
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.nav-enter-active,
.nav-leave-active {
    transition: 0.5s ease;
    pointer-events: none;
}
.nav-enter-from,
.nav-leave-to {
    opacity: 0;
    transform: scale(0.5);
}

.header {
    position: absolute;
    left: 0;
    right: 0;
    padding: 2.35em 0;
    font-size: $font14;
    &__inner {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        gap: 3.1em;
        & > * {
            display: flex;
            gap: inherit;
            z-index: 3;
            &:nth-child(1) {
                justify-self: start;
            }
            &:nth-child(2) {
            }
            &:nth-child(3) {
                justify-self: end;
            }
        }
    }
    &__logo {
        position: relative;
        z-index: 15;
    }
    &__nav {
        & > * {
            /* background: #000; */
            position: relative;
            &::before,
            &::after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='161' viewBox='0 0 8 161' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.64645 160.354C3.84172 160.549 4.1583 160.549 4.35356 160.354L7.53554 157.172C7.7308 156.976 7.7308 156.66 7.53554 156.464C7.34028 156.269 7.0237 156.269 6.82843 156.464L4.00001 159.293L1.17158 156.464C0.976318 156.269 0.659735 156.269 0.464473 156.464C0.269211 156.66 0.269211 156.976 0.464473 157.172L3.64645 160.354ZM3.5 2.18557e-08L3.50001 160L4.50001 160L4.5 -2.18557e-08L3.5 2.18557e-08Z' fill='%237746CA'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center bottom;
                display: block;
                position: absolute;
                height: 10rem;
                width: 0.5rem;
                left: 0;
                bottom: calc(100% + 0.75em);

                opacity: 0;
                transform: translateY(-4.5em);

                transition: 0.25s;
                transition-property: opacity, transform;
            }
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='161' viewBox='0 0 8 161' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.64645 160.354C3.84172 160.549 4.1583 160.549 4.35356 160.354L7.53554 157.172C7.7308 156.976 7.7308 156.66 7.53554 156.464C7.34028 156.269 7.0237 156.269 6.82843 156.464L4.00001 159.293L1.17158 156.464C0.976318 156.269 0.659735 156.269 0.464473 156.464C0.269211 156.66 0.269211 156.976 0.464473 157.172L3.64645 160.354ZM3.5 2.18557e-08L3.50001 160L4.50001 160L4.5 -2.18557e-08L3.5 2.18557e-08Z' fill='%237746CA'/%3E%3C/svg%3E%0A");
            }
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='161' viewBox='0 0 8 161' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.64645 160.354C3.84172 160.549 4.1583 160.549 4.35356 160.354L7.53554 157.172C7.7308 156.976 7.7308 156.66 7.53554 156.464C7.34028 156.269 7.0237 156.269 6.82843 156.464L4.00001 159.293L1.17158 156.464C0.976318 156.269 0.659735 156.269 0.464473 156.464C0.269211 156.66 0.269211 156.976 0.464473 157.172L3.64645 160.354ZM3.5 2.18557e-08L3.50001 160L4.50001 160L4.5 -2.18557e-08L3.5 2.18557e-08Z' fill='%23FFD600'/%3E%3C/svg%3E%0A");
            }

            &.router-link-active {
                &::before {
                    transform: translateY(0);
                }
                &::after {
                    transform: translateY(0);
                }
                [theme="white"] &::before {
                    opacity: 1;
                }
                [theme="primary"] &::after {
                    opacity: 1;
                }
            }
        }
    }
    @media (max-width: 979px) {
        &__logo {
            margin-left: 0.7rem;
        }
    }
}

.header {
    &__mobile {
        // position: relative;
        grid-column: span 2;
        justify-self: end;

        position: fixed;
        right: 1em;
        top: 1em;
        z-index: 999;
    }
    &__mobile_logo {
        z-index: 999;
    }
    &__mobile_nav_toggle {
        width: 1.5em;
        height: 1.28em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.5em;
        transition: 0.25s;
        transition-property: gap color;

        position: relative;

        & > * {
            height: 0.1875em;
            margin: calc(0.1875em / -2) 0;
            border-radius: 0.1875em;
            background: currentColor;

            transition: inherit;
            transition-property: transform opacity;
        }
        [theme="primary"] & {
            color: #fff;
        }

        &.active {
            color: #000;
            gap: 0;

            & > * {
                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
    &__inner {
        position: relative;
    }
    &__mobile_nav {
        position: absolute;
        top: 100%;
        right: 0;
        display: grid;
        background: #f00;
        grid-gap: 1em;
        padding: 1em;
        background: #fff;
        border-radius: 1em;
        transform-origin: top right;
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 979px) {
        padding: 0.5rem 0;
        font-size: min(18px, 1rem);
    }
}
</style>
<!-- mobile-menu -->
<style lang="scss">
.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: -100px;
    padding-bottom: 100px;
    min-width: 0;
    overflow: auto;
    z-index: 99;
    background: #fff;
    &__logo {
        display: flex;
        justify-content: center;
        padding: 1.25rem 0;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    }
    &__list {
        display: grid;
        justify-items: center;
        gap: 1.5em;
        padding: 2em 0;
        & > * {
            padding: 0.5em;
            border-radius: 0.35em;

            &.router-link-active {
                background: var(--color-purple);
                color: #fff;
            }
        }
    }
    &__feedback {
        .btn {
        }
    }
    &__footer {
        margin-top: 3em;
        padding-bottom: 2em;
        font-size: 0.7em;
    }
    .socials {
        &__title {
        }
        &__list {
        }
        &__item {
        }
    }
}
</style>

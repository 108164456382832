<template>
    <div class="wrapper" theme="white" :class="[is_active && 'active', state]" @mousedown.self="goTo()" ref="main">
        <span class="close" @click.self="goTo()" v-if="is_active"></span>
        <span class="backdrop"></span>
        <!-- <div class="card-handler" ref="handler" v-if="!is_active">
            <svg width="86" height="8" viewBox="0 0 86 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1151_12)">
                    <path
                        class="handler-arrow"
                        d="M13.6464 3.64645C13.4512 3.84172 13.4512 4.1583 13.6464 4.35356L16.8284 7.53554C17.0237 7.7308 17.3403 7.7308 17.5355 7.53554C17.7308 7.34028 17.7308 7.0237 17.5355 6.82843L14.7071 4.00001L17.5355 1.17158C17.7308 0.976318 17.7308 0.659735 17.5355 0.464473C17.3403 0.269211 17.0237 0.269211 16.8284 0.464473L13.6464 3.64645ZM58 3.5L14 3.50001L14 4.50001L58 4.5L58 3.5Z"
                        fill="currentColor"
                    />
                </g>
                <g clip-path="url(#clip1_1151_12)">
                    <path d="M86 4L45 4" stroke="#7746CA" />
                </g>
                <defs>
                    <clipPath id="clip0_1151_12">
                        <rect width="45" height="8" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_1151_12">
                        <rect class="handler-line-box" width="31" height="8" fill="white" transform="translate(45)" />
                    </clipPath>
                </defs>
            </svg>
            ТЯНИ
        </div> -->
        <div class="card" ref="card">
            <div class="card-content" ref="card_inner">
                <div class="card-title" v-if="state === 'works' || state === 'works-detail'">
                    <span>
                        ПРИМЕРЫ
                        <br />
                        РАБОТ
                    </span>
                </div>
                <!-- <transition name="fade"> -->
                <div class="video-wrapper" v-if="state === 'presentation'">
                    <!-- presentation -->
                    <video src="/assets/pres.mp4" poster="/assets/pres_poster.jpg" style="width: 100%" controls="true"></video>
                </div>
                <div v-else-if="state === 'works'">
                    <!-- works -->

                    <works-main></works-main>
                </div>
                <div v-else-if="state === 'works-detail'">
                    <!-- works-detail -->

                    <works-detail></works-detail>
                </div>
                <div class="video-wrapper" v-else-if="state === 'presentation-mini'">
                    <!-- presentation-mini -->
                    <video src="/assets/pres_mini.mp4" poster="/assets/pres_poster.jpg" style="width: 100%" muted autoplay loop playsinline></video>
                </div>
                <div class="video-wrapper" v-else>
                    <!-- works-mini -->
                    <video src="/assets/Video_2.mp4" poster="/assets/works_poster.jpg" style="width: 100%" muted autoplay loop playsinline></video>
                </div>
                <!-- </transition> -->
            </div>
            <hr v-if="state === 'works' || state === 'works-detail'" />

            <div class="card-navigation">
                <template v-if="state === 'presentation'">
                    <span class="card-navigation-state" disabled>
                        <svg width="63" height="63" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                            <use xlink:href="#img-ico-presentation"></use>
                        </svg>
                        ПРЕЗЕНТАЦИЯ</span
                    >
                    <router-link class="btn" to="/feedback" data-btn-text="СВЯЗАТЬСЯ С НАМИ"></router-link>
                    <button class="btn btn--outline" @click="goTo('works')">
                        <svg width="63" height="63" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                            <use xlink:href="#img-ico-works"></use>
                        </svg>
                        ПРИМЕРЫ РАБОТ
                    </button>
                </template>

                <template v-else-if="state === 'works'">
                    <span class="card-navigation-state" disabled>
                        <svg width="63" height="63" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                            <use xlink:href="#img-ico-works"></use>
                        </svg>
                        ПРИМЕРЫ РАБОТ</span
                    >
                    <router-link class="btn" to="/feedback" data-btn-text="СВЯЗАТЬСЯ С НАМИ"></router-link>
                    <button class="btn btn--outline" @click="goTo('presentation')">
                        <svg width="63" height="63" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                            <use xlink:href="#img-ico-presentation"></use>
                        </svg>
                        ПРЕЗЕНТАЦИЯ
                    </button>
                </template>

                <template v-else-if="state === 'works-detail'">
                    <button class="btn btn--outline" @click="goTo('works')">
                        <svg width="63" height="63" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                            <use xlink:href="#img-ico-works"></use>
                        </svg>
                        ПРИМЕРЫ РАБОТ
                    </button>
                    <router-link class="btn" to="/feedback" data-btn-text="СВЯЗАТЬСЯ С НАМИ"></router-link>
                    <button class="btn btn--outline" @click="goTo('presentation')">
                        <svg width="63" height="63" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                            <use xlink:href="#img-ico-presentation"></use>
                        </svg>
                        ПРЕЗЕНТАЦИЯ
                    </button>
                </template>

                <template v-else-if="state === 'presentation-mini'">
                    <a class="btn btn--white card-btn" @click="goTo('presentation')">
                        <svg width="63" height="63" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                            <use xlink:href="#img-ico-presentation"></use>
                        </svg>
                        ПРЕЗЕНТАЦИЯ</a
                    >
                </template>

                <template v-else>
                    <a class="btn btn--white card-btn" @click="goTo('works')">
                        <svg width="63" height="63" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                            <use xlink:href="#img-ico-works"></use>
                        </svg>
                        ПРИМЕРЫ РАБОТ</a
                    >
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import WorksMain from "@/components/works/WorksMain.vue";
import WorksDetail from "@/components/works/WorksDetail.vue";
export default {
    data() {
        return {
            duration: 0.35,

            is_handler_active: false,
            handler_start_x: 0,
            dx: 0,
        };
    },
    components: { WorksMain, WorksDetail },
    props: ["state"],
    computed: {
        is_active() {
            return this.state && !this.state.includes("-mini");
        },
    },
    watch: {
        state() {
            this.is_active && this.transition();
        },
        is_active(val) {
            // console.log(val);
            this.transition();
        },
    },
    methods: {
        goTo(box_state = "", work_id) {
            // console.log(this.$route);
            if (this.$route.params.box_state) {
                this.$router.push({
                    params: {
                        box_state,
                        work_id,
                    },
                });
            } else {
                let target_route = this.$route.path
                    //
                    .split("/")
                    .filter((i) => i);
                //
                if (this.is_active) {
                    target_route = target_route.slice(0, -1);
                }
                target_route
                    //
                    .push(box_state);
                target_route = "/" + target_route.join("/");
                console.log(target_route);
                this.$router.push(target_route);
            }
        },
        startAnimation() {
            const { timeline } = this.$gsap;
            return timeline();
            //     let card = this.$refs.card;
            //     return timeline().from(card, {
            //         x: "30%",
            //         opacity: 0,
            //         duration: 2,
            //     });
        },
        transition() {
            // console.log("transition");
            let { timeline, set } = this.$gsap;
            let card = this.$refs.card;
            let main = this.$refs.main;
            let card_inner = this.$refs.card_inner;
            let start_bounding_box = card.getBoundingClientRect();
            let inner_start_bounding_box = card_inner.getBoundingClientRect();
            this.$nextTick(() => {
                main.scrollTo(0, 0);

                set(main, {
                    clearProps: "all",
                });

                let end_bounding_box = card.getBoundingClientRect();
                let inner_end_bounding_box = card_inner.getBoundingClientRect();

                timeline()
                    .set(card, {
                        position: "fixed",
                        overflow: "hidden",
                        width: start_bounding_box.width,
                        height: start_bounding_box.height,
                        top: start_bounding_box.top,
                        left: start_bounding_box.left,
                    })
                    // .from(main, {
                    //     width: start_bounding_box.width,
                    //     duration: this.duration,
                    // })
                    .to(
                        card,
                        {
                            width: end_bounding_box.width,
                            height: end_bounding_box.height,
                            top: end_bounding_box.top,
                            left: end_bounding_box.left,

                            duration: this.duration,
                        },
                        "<0%"
                    )
                    .set(card, {
                        clearProps: "all",
                    });

                // timeline()
                //     .set(card_inner, {
                //         width: inner_end_bounding_box.width,
                //         transformOrigin: "0 0",
                //     })
                //     .from(card_inner, {
                //         scaleX: inner_start_bounding_box.width / inner_end_bounding_box.width,
                //         scaleY: inner_start_bounding_box.height / inner_end_bounding_box.height,
                //         duration: 0.75,
                //     })
                //     .set(card_inner, {
                //         clearProps: "all",
                //     });
            });
        },
        // mouseDown(e) {
        //     console.log("mouseDown");
        //     this.is_handler_active = true;
        //     this.handler_start_x = e.clientX;
        // },
        // mouseMove(e) {
        //     const { set } = this.$gsap;

        //     if (!this.is_handler_active) return;
        //     console.log("mouseMove");
        //     let current_x = e.clientX;
        //     this.dx = this.handler_start_x - current_x;
        //     if (this.dx > 200) {
        //         this.mouseUp();
        //         return;
        //     }

        //     let main = this.$refs.main;
        //     let main_width = main.getBoundingClientRect().width;

        //     // console.log(dx);
        //     // console.log(main);
        //     // console.log(main_width + dx);

        //     set(main, {
        //         clearProps: "width",
        //     });
        //     set(main, {
        //         width: "+=" + this.dx,
        //     });
        // },
        // mouseUp(e) {
        //     if (!this.is_handler_active) return;
        //     // let current_x = e.clientX;
        //     // let dx = this.handler_start_x - current_x;
        //     // if (dx > 200) {
        //     //     this.mouseUp();
        //     //     return;
        //     // }
        //     if (this.dx > 100) {
        //         // console.log("open");
        //         this.goTo(this.state.split("-")[0]);
        //     } else {
        //         this.transition();
        //     }
        //     // this.transition();
        //     this.is_handler_active = false;
        //     this.handler_start_x = 0;
        //     this.dx = 0;
        // },
    },
    mounted() {
        let handler = this.$refs.handler;
        // handler.addEventListener("mousedown", this.mouseDown);
        // document.body.addEventListener("mousemove", this.mouseMove);
        // document.body.addEventListener("mouseup", this.mouseUp);
    },
    ummounted() {
        let handler = this.$refs.handler;
        // handler.removeEventListener("mousedown", this.mouseDown);
        // document.body.removeEventListener("mousemove", this.mouseMove);
        // document.body.removeEventListener("mouseup", this.mouseUp);
    },
};
</script>

<style lang="scss" scoped>
.fade {
    &-leave-active,
    &-enter-active {
        transition: all 0.7s;
    }
    &-enter-from,
    &-leave-to {
        opacity: 0;
        width: 100%;
    }
    &-enter-to,
    &-leave-from {
        opacity: 1;
        position: absolute;
    }
}

.wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;
}

.card-handler {
    font-size: 0.85rem;

    position: absolute;
    z-index: 2;
    right: calc(100% - 4em);
    // top: 40%;
    bottom: 8rem;
    padding: 0.5em 1.1em 0.5em 3.1em;

    background: var(--color-yellow);
    color: var(--color-purple);
    border-radius: 2em;
    font-weight: 700;

    user-select: none;
    cursor: grab;

    transition: 0.25s;
    transition-property: padding;

    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 6.15em;

        max-width: unset;
        left: -3.2em;

        color: var(--color-purple);

        transition: 0.25s;
        transition-property: color;
        .handler-arrow {
            transition: 0.25s;
            transition-property: transform;
        }
        .handler-line-box {
            transition: 0.25s;
            transition-property: width;
        }

        [theme="primary"] & {
            transition-delay: 0.1s;
            color: #fff;
        }
    }

    &:active {
        cursor: grabbing;

        padding-left: calc(3.1em + 1em);

        svg {
            .handler-arrow {
                transform: translateX(-10px);
            }
            .handler-line-box {
                width: 41px;
            }
        }
    }

    @media (max-width: 979px) {
        display: none;
    }
}

.card {
    overflow: hidden;
    padding: 0.5rem;
    padding-bottom: 2rem;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 10px 0px #0000001a;
    border-radius: 1.25rem;

    width: 86.25rem;
    max-width: 100%;

    .active & {
        padding-bottom: 0 !important;

        @media (min-width: 980px) {
            padding: 1.75rem;
        }
    }
    .active & .card-content {
        width: 82.75rem;
    }

    .active & .card-navigation {
        width: 72.375rem;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .active.works &,
    .active.works-detail & {
        width: 103.75rem;
    }
    .active.works & .card-content,
    .active.works-detail & .card-content {
        width: 86.75rem;
    }
    .active.works & .card-navigation,
    .active.works-detail & .card-navigation {
        width: 86.25rem;
    }
}
.card-title {
    display: flex;
    text-align: right;

    font-weight: 900;
    gap: 0.55em;
    margin-top: 0.55em;
    &::before {
        content: "";
        flex: 1;
        flex-basis: 100%;
        border-top: 2px solid currentColor;
        margin-top: 0.5em;
    }

    color: var(--color-purple);

    font-size: 1.67rem;

    transition: 0.25s;
    transition-property: color;

    .works-detail & {
        color: rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 980px) {
        font-size: 2.8rem;
    }
}
.card-content {
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 100%;

    flex: 1;
}
hr {
    border: 0px solid transparent;
    border-top: 1px solid #e1e1e1;
    display: block;
    width: 100%;
    margin: 0;
}
.card-navigation {
    width: 100%;
    max-width: 100%;

    display: grid;
    grid-gap: 1rem;

    transition: 0.35s;

    @media (min-width: 980px) {
        grid-template-columns: 1fr auto 1fr;
        .btn {
            width: 21.25rem;
        }
        & > * {
            &:first-child {
                justify-self: start;
                width: fit-content;
            }
            &:last-child {
                justify-self: end;
                width: 19.75rem;
            }
        }
    }
}
.card-navigation-state {
    align-self: center;
    font-weight: 900;
    opacity: 0.2;

    position: relative;
    svg {
        margin: -1.5rem 0;
        margin-left: -0.5rem;
        margin-right: 0.5rem;
        width: 3em;
        height: auto;
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        right: calc(100% + 1rem);
        width: 20em;
        height: 0.75em;
        top: 0.075em;
        background-image: url("data:image/svg+xml,%3Csvg width='476' height='16' viewBox='0 0 476 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M475.707 8.70711C476.098 8.31658 476.098 7.68342 475.707 7.29289L469.343 0.928932C468.953 0.538408 468.319 0.538408 467.929 0.928932C467.538 1.31946 467.538 1.95262 467.929 2.34315L473.586 8L467.929 13.6569C467.538 14.0474 467.538 14.6805 467.929 15.0711C468.319 15.4616 468.953 15.4616 469.343 15.0711L475.707 8.70711ZM0 9L475 9V7L0 7L0 9Z' fill='black'/%3E%3C/svg%3E%0A");
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.video-wrapper {
    overflow: hidden;
    border-radius: 1.25rem;
    width: 100%;
    video {
        display: block;
        width: 100%;
    }
}
.card-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    // background: rgba(255, 255, 255, 0.9);
    // padding: 1em;
    // width: 15.3em;
    // text-align: center;
    // border-radius: 10rem;

    // font-weight: 800;
    // line-height: 1.2;
    // color: var(--color-purple);

    cursor: pointer;
}

.wrapper.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-height: 100vh;

    overflow: auto;

    .backdrop {
        backdrop-filter: blur(10px);
    }

    padding-top: 2.5rem;
    padding-bottom: calc(var(--footer-height) + 2rem);
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .card {
    }
    .card-content {
    }
    .video-wrapper {
    }
    .card-navigation {
    }
    .card-btn {
    }
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const SITE_NAME = "GoodAds";

// hide_navigation;
// hide_pagination;
// hide_feedback;
// hide_presentation;

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            box_state: "presentation-mini",
            theme: "white",
        },
    },
    {
        path: "/about",
        name: "about",
        component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
        meta: {
            box_state: "presentation-mini",
            theme: "primary",
            title: "О КОМПАНИИ",
        },
    },
    {
        path: "/advertising",
        name: "advertising",
        component: () => import(/* webpackChunkName: "advertising" */ "../views/AdvertisingView.vue"),
        meta: {
            box_state: "works-mini",
            theme: "white",
            title: "РЕКЛАМА",
        },
        alias: ["/advertising/:box_state(presentation)", "/advertising/:box_state(works)/:work_id?"],
    },
    {
        path: "/reputation-management",
        name: "reputation-management",
        component: () => import(/* webpackChunkName: "reputation-management" */ "../views/ReputationManagementView.vue"),
        meta: {
            box_state: "works-mini",
            theme: "primary",
            title: "РАБОТА С РЕПУТАЦИЕЙ",
        },
        alias: ["/reputation-management/:box_state(presentation)", "/reputation-management/:box_state(works)/:work_id?"],
    },
    {
        path: "/web-development",
        name: "web-development",
        component: () => import(/* webpackChunkName: "web-development" */ "../views/WebDevelopmentView.vue"),
        meta: {
            box_state: "works-mini",
            theme: "white",
            title: "WEB - РАЗРАБОТКА",
        },
        alias: ["/web-development/:box_state(presentation)", "/web-development/:box_state(works)/:work_id?"],
    },
    {
        path: "/consulting",
        name: "consulting",
        component: () => import(/* webpackChunkName: "consulting" */ "../views/ConsultingView.vue"),
        meta: {
            box_state: "works-mini",
            theme: "primary",
            title: "КОНСАЛТИНГ",
        },
        alias: ["/consulting/:box_state(presentation)", "/consulting/:box_state(works)/:work_id?"],
    },
    {
        path: "/contacts",
        name: "contacts",
        meta: {
            hide_feedback: true,
            box_state: "works-mini",
            // hide_presentation: true,
            theme: "white",
            title: "КОНТАКТЫ",
        },
        component: () => import(/* webpackChunkName: "contacts" */ "../views/ContactsView.vue"),
    },
    {
        path: "/feedback",
        name: "feedback",
        meta: {
            hide_feedback: true,
            // hide_navigation: true,
            hide_pagination: true,
            hide_presentation: true,
            theme: "white",
            title: "Связаться с нами",
        },
        component: () => import(/* webpackChunkName: "feedback" */ "../views/FeedbackView.vue"),
    },
    {
        path: "/not-found",
        name: "not-found",
        meta: {
            // hide_pagination: true,
            // hide_presentation: true,
            theme: "primary",
            title: "404",
        },
        component: () => import(/* webpackChunkName: "not_found" */ "../views/NotFoundView.vue"),
    },
    { path: "/:pathMatch(.*)*", redirect: "not-found" },
];
routes.map((route) => {
    route.path = route.path + (route.path === "/" ? "" : "/") + ":box_state(presentation|works)?";
    route.alias = [route.path + (route.path === "/" ? "" : "/") + ":box_state(works)/:work_id?"];
});

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to) => {
    document.title = [SITE_NAME, to.meta.title && to.meta.title.toLowerCase()].filter((el) => el).join(" - ");
    document.body.setAttribute("theme", to.meta.theme);
});

export default router;

import { createStore } from "vuex";
import { markRaw } from "vue";
import { slugify } from "transliteration";
import axios from "axios";

import vkLogo from "../assets/vk_logo.vue";
import okLogo from "../assets/ok_logo.vue";

export default createStore({
    state: {
        contacts: {
            phone: "+7 (495) 255-07-16",
            email: "info@goodads.ru",
            address: "Россия, Москва, Столярный переулок 3к13",
            socials: [
                { link: "https://vk.com", template: markRaw(vkLogo) },
                { link: "https://ok.ru", template: markRaw(okLogo) },
            ],
            copyright: "Good Ads © 2023. Все права защищены",
        },
        logo_pos: { cx: 0, cy: 0 },
        works_raw: [],
    },
    getters: {
        works: (state) => {
            return state.works_raw.map((item) => {
                item.tags = item.tags.split(",");
                item.page = item.category || "Главная";
                delete item.category;
                return item;
            });
        },
        logo_pos(state) {
            return state.logo_pos;
        },
    },
    mutations: {
        set_works_raw(state, payload) {
            state.works_raw = payload;
        },
        set_logo_pos(state, payload) {
            state.logo_pos = payload;
        },
    },
    actions: {
        fetch_works_raw: async (context, name) => {
            let { data } = await axios.get("https://goodads.ru/api/list");
            context.commit("set_works_raw", data);
        },
    },
    modules: {},
});

<template>
    <section class="section" :data-section-theme="theme" ref="section">
        <div class="container">
            <div class="section__inner">
                <slot></slot>
                <slot name="title"></slot>
                <slot name="assets" v-if="true"></slot>
                <slot name="content"></slot>
            </div>
        </div>
        <span class="section__circle" ref="circle"></span>
    </section>
</template>

<script>
export default {
    computed: {
        theme() {
            return this.$route.meta.theme;
        },
    },
    methods: {
        whiteToPurple() {
            let { timeline } = this.$gsap;
            let screen = this.$refs.section;
            let circle = this.$refs.circle;
            let logoPos = this.$store.state.logo_pos;

            return timeline()
                .fromTo(
                    screen,
                    { maskImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px, #000 0% ,transparent 0%)` },
                    {
                        maskImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px, #000 100% ,transparent 100%)`,
                        ease: "power2",
                        duration: 2,
                    }
                )
                .fromTo(
                    circle,
                    { backgroundImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px,transparent 0% ,#ffd600 0%)` },
                    {
                        backgroundImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px,transparent 100% ,#ffd600 100%)`,
                        ease: "power1",
                        duration: 2,
                    },
                    "<0"
                );
        },
        purpleToWhite() {
            let { timeline } = this.$gsap;
            let screen = this.$refs.section;
            let circle = this.$refs.circle;
            let logoPos = this.$store.state.logo_pos;

            return timeline()
                .fromTo(
                    screen,
                    { maskImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px, transparent 100% ,#000 100%)` },
                    {
                        maskImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px, transparent 0% ,#000 0%)`,
                        ease: "power2",
                        duration: 2,
                    }
                )
                .fromTo(
                    circle,
                    { backgroundImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px,#ffd600 110% , transparent 110%)` },
                    {
                        backgroundImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px,#ffd600 0% , transparent 0%)`,
                        ease: "power1",
                        duration: 2,
                    },
                    "<0"
                );
        },
        sameToSame() {
            let { timeline, set } = this.$gsap;
            let screen = this.$refs.section;
            let circle = this.$refs.circle;
            let logoPos = this.$store.state.logo_pos;

            let color_stop1 = this.theme === "primary" ? "#fff" : "#7746ca";
            let color_stop2 = this.theme === "primary" ? "#7746ca" : "#fff";

            let circleState = {
                value: 0,
            };
            function clamp(min, max, value) {
                if (value < min) {
                    return min;
                } else if (value > max) {
                    return max;
                }
                return value;
            }

            return timeline()
                .fromTo(
                    screen,
                    {
                        maskImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px ,#0000 100%,#000 100%)`,
                    },
                    {
                        maskImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px ,#0000 -20%,#000 -20%)`,
                        ease: "power3.out",
                        duration: 2.5,
                    }
                )
                .fromTo(
                    circleState,
                    { value: 130 },
                    {
                        value: 0,

                        onUpdate() {
                            let val = circleState.value;

                            set(circle, {
                                backgroundImage: `radial-gradient(circle at ${logoPos.cx}px ${logoPos.cy}px , ${color_stop1} ${clamp(0, 100, val - 30)}% ,${color_stop2} ${clamp(
                                    0,
                                    100,
                                    val - 30
                                )}% , ${color_stop2} ${clamp(0, 100, val - 15)}% ,#ffd600 ${clamp(0, 100, val - 15)}% , #ffd600 ${clamp(0, 100, val - 0)}% ,#0000 ${clamp(0, 100, val - 0)}% )`,
                            });
                        },
                        ease: "power1.out",
                        duration: 2.5,
                    },
                    0
                );
        },
        change(last_theme) {
            const { timeline, set } = this.$gsap;
            const section = this.$refs.section;
            set(section, {
                position: "absolute",
                top: 0,
            });

            let change_animation = null;

            if (this.theme === last_theme) {
                change_animation = this.sameToSame();
            } else {
                if (last_theme === "primary") {
                    change_animation = this.purpleToWhite();
                } else {
                    change_animation = this.whiteToPurple();
                }
            }

            return change_animation.set(section, {
                position: "",
                top: "",
            });
        },
    },
    mounted() {
        const { timeline } = this.$gsap;
        // из страниц добавляем анимации в timeline
        this.animation = timeline();
        this.$emit("onContentMounted", this.animation, this.change);
    },
};
</script>

<style lang="scss" scoped>
.section {
    padding-bottom: calc(var(--footer-safe-area) + 2rem) !important;
    &__circle {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 110;

        pointer-events: none;
    }
}
</style>

<template>
    <h1 class="section__title" ref="title">
        <slot>
            <div class="hide_overflow" v-for="(str, i) in content" v-bind:key="i">
                <b>
                    <span class="slide_up"> {{ str.toUpperCase() }}</span>
                </b>
            </div>
        </slot>
    </h1>
</template>

<script>
export default {
    name: "SectionTitle",
    props: ["content"],
    mounted() {
        let { from } = this.$gsap;
        let title = this.$refs.title;
        let slide_up = title.querySelectorAll(".slide_up");
        this.animation = from(slide_up, {
            y: "1.15em",
            duration: 1,
            stagger: 1 / 3,
        });
    },
};
</script>

<!-- <style lang="scss">
.hide-overflow {
  overflow: hidden;
  & > * {
    display: inline-block;
  }
}
</style> -->

<template>
    <div class="controls">
        <swiper :slides-per-view="'auto'" class="pages">
            <swiper-slide v-for="page in pages" :key="page" @click="setPage(page)" :class="{ active: page === current_page }">
                {{ page }}
            </swiper-slide>
            <template v-slot:container-end v-if="!data.length">
                <div class="swiper-wrapper">
                    <div class="swiper-slide slide"><div v-if="!pages.length" style="width: 12em" class="placeholder"></div></div>
                    <div class="swiper-slide slide"><div v-if="!pages.length" style="width: 8em" class="placeholder"></div></div>
                    <div class="swiper-slide slide"><div v-if="!pages.length" style="width: 8em" class="placeholder"></div></div>
                </div>
            </template>
        </swiper>

        <div class="tags">
            <template v-for="(tag, index) in tags" :key="tag">
                <div @click="toggleTag(tag)" :class="{ active: current_tags.includes(tag) }">{{ tag }}</div>
                {{ index !== tags.length - 1 ? " | " : "" }}
            </template>
            <div v-if="!tags.length" style="width: 4em" class="placeholder active">.</div>
            <div v-if="!tags.length" style="width: 6em" class="placeholder active">.</div>
            <div v-if="!tags.length" style="width: 3em" class="placeholder active">.</div>
            <div v-if="!tags.length" style="width: 8em" class="placeholder active">.</div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
    props: ["data"],
    components: { Swiper, SwiperSlide },
    computed: {
        pages() {
            let pages = new Set();
            this.data.forEach((item) => pages.add(item.page));
            return Array.from(pages);
        },
        tags() {
            let tags = new Set();
            this.data
                .filter((item) => item.page === this.current_page)
                .forEach((item) => {
                    item.tags.forEach((tag) => tags.add(tag));
                });
            return Array.from(tags);
        },
        current_page() {
            let value = this.query_page || this.pages[0];
            this.$emit("onUpdatePage", value);
            return value;
        },
        current_tags() {
            let value = !this.query_tags.length ? this.tags : this.query_tags;
            this.$emit("onUpdateTags", value);
            return value;
        },
        query_page() {
            return this.$route.query.page;
        },
        query_tags() {
            return this.$route.query.tags ? this.$route.query.tags.split(",") : [];
        },
    },
    methods: {
        setPage(page) {
            this.$router.replace({
                query: {
                    page,
                },
            });
        },
        toggleTag(tag) {
            let tags = this.query_tags;
            if (tags.includes(tag)) {
                tags.splice(tags.indexOf(tag), 1);
            } else {
                tags.push(tag);
            }
            let query = {
                page: this.current_page,
            };
            if (tags.join(",")) {
                query.tags = tags.join(",");
            }
            this.$router.replace({
                query,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.controls {
    // display: grid;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;

    & > * {
        width: 100%;
    }
}
.pages {
    font-size: 0.9rem;
    .swiper-wrapper > * {
        width: fit-content;
        display: grid;
        place-items: center;
        padding: 0 1rem;
        padding-top: 1px;
        height: 2.4em;
        text-transform: uppercase;
        border-radius: 0.35em;
        cursor: pointer;

        border: 1px solid #adadad;
        position: relative;

        transition: 0.25s;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
            border: inherit;
            border-width: 0px;
            border-color: transparent;
            border-left-color: transparent !important;
            border-top-color: transparent !important;

            transition: inherit;
        }
        &.active {
            border-color: var(--color-purple);
            background: var(--color-purple);
            color: #fff;

            &::after {
                border-color: var(--color-purple);
                border-width: 0.35em;
            }
        }
    }
}
.tags {
    // display: flex;
    gap: 0.5em;
    color: rgba(0, 0, 0, 0.2);

    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
    & > * {
        display: inline-block;
        cursor: pointer;
        &.active {
            color: #000;
        }
    }
}

@media (max-width: 979px) {
    .controls {
        grid-gap: 0.5rem;
    }

    .pages {
        font-size: 0.75rem;
        .swiper-wrapper > * {
            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
    }
}
</style>

<template>
    <div class="logo">
        <span class="logo__wrapper" :style="{ color }" ref="logo_wrapper">
            <span class="logo__background" :style="{ background }" ref="background"></span>

            <span class="logo__circle logo__circle-1" :style="{ background }" ref="circle1"></span>
            <span class="logo__circle logo__circle-2" style="background: var(--color-yellow)" ref="circle2"></span>
            <span class="logo__circle logo__circle-3" :style="{ background: color }" ref="circle3"></span>

            <svg width="120" height="88" viewBox="0 0 120 88" fill="none" xmlns="http://www.w3.org/2000/svg" class="logo__element">
                <!-- logo -->
                <mask :id="'mask2_929_9' + id" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="33" y="0" width="49" height="49">
                    <circle cx="57.5" cy="24.5" r="24.5" fill="#D9D9D9" ref="logo" />
                </mask>
                <g :mask="'url(#mask2_929_9' + id + ')'">
                    <path
                        d="M44.0975 8.10392C46.5222 8.10392 48.8258 8.50072 51.0081 9.28758C53.1837 10.0744 54.9619 11.1572 56.3359 12.5292L50.2403 19.8462C49.5937 19.0863 48.8393 18.481 47.9569 18.0304C47.0813 17.5798 45.9969 17.3512 44.7104 17.3512C43.8617 17.3512 43.0333 17.5193 42.2317 17.8555C41.4302 18.1918 40.7162 18.676 40.1033 19.3015C39.4904 19.9269 38.9919 20.6802 38.6148 21.5544C38.2376 22.4287 38.0422 23.4106 38.0422 24.4867C38.0422 26.6455 38.608 28.4075 39.7329 29.766C40.8577 31.1245 42.6628 31.8038 45.1482 31.8038C45.586 31.8038 46.0373 31.7701 46.5088 31.6961C46.9735 31.6222 47.344 31.5146 47.6067 31.3666V28.9993H42.6022V20.8079H56.8613V33.9357L71.0867 8.98493L81.7423 27.5803C81.877 26.5311 81.978 25.4686 81.978 24.3858C81.978 10.9151 71.0395 0 57.555 0C49.9574 0 43.1747 3.46353 38.6956 8.89081C40.3929 8.37297 42.1913 8.10392 44.0975 8.10392Z"
                        fill="currentColor"
                    />
                    <path
                        d="M53.406 39.9951L53.9179 39.0939C53.0827 39.3966 52.2272 39.679 51.3112 39.9077C50.2403 40.17 49.1222 40.3852 47.9569 40.5399C46.7849 40.7013 45.6197 40.782 44.4477 40.782C42.4405 40.782 40.5681 40.5129 38.8034 40.0085C43.2825 45.3618 50.018 48.7716 57.5483 48.7716C65.0853 48.7716 71.8276 45.3551 76.3067 39.9951H53.406Z"
                        fill="currentColor"
                    />
                </g>
                <!-- good -->
                <mask :id="'mask1_929_9' + id" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="63" width="70" height="25">
                    <rect y="63" width="70" height="25" fill="#D9D9D9" />
                </mask>
                <g :mask="'url(#mask1_929_9' + id + ')'" ref="good">
                    <path
                        d="M18.0512 69.2701C17.2766 68.4563 16.3538 67.8242 15.2896 67.3736C14.2254 66.923 12.9996 66.701 11.6188 66.701C10.1976 66.701 8.91782 66.9633 7.77278 67.4812C6.63448 68.0058 5.66457 68.7052 4.86978 69.5929C4.07499 70.4806 3.46206 71.5029 3.03098 72.6731C2.59991 73.8433 2.39111 75.074 2.39111 76.3652C2.39111 77.6565 2.60665 78.8872 3.03098 80.0574C3.46206 81.2276 4.06825 82.2499 4.86978 83.1241C5.66457 83.9984 6.63448 84.6978 7.77278 85.2224C8.91109 85.747 10.1908 86.0025 11.6188 86.0025C12.8716 86.0025 14.0233 85.868 15.0674 85.5923C16.1114 85.3233 16.96 84.9735 17.6066 84.5566V77.8179H12.427V75.8138H19.863V85.8344C18.6506 86.5674 17.344 87.1122 15.943 87.4686C14.542 87.8251 13.0871 88 11.5784 88C9.90121 88 8.35877 87.704 6.93084 87.1256C5.50965 86.5405 4.28379 85.7335 3.25999 84.6978C2.23619 83.6621 1.43466 82.4382 0.862146 81.0191C0.289626 79.6001 0 78.0398 0 76.3518C0 74.657 0.289629 73.1035 0.87562 71.6845C1.46161 70.2654 2.26987 69.0347 3.30714 68.0057C4.34441 66.97 5.56354 66.163 6.978 65.5779C8.39246 64.9928 9.92142 64.7037 11.5784 64.7037C13.3565 64.7037 14.9057 64.9659 16.2259 65.4972C17.546 66.0285 18.6709 66.7683 19.6138 67.7098L18.0512 69.2701Z"
                        fill="currentColor"
                    />
                    <path
                        d="M36.6614 80.1112C36.6614 81.2209 36.4661 82.2431 36.0822 83.1846C35.6915 84.1262 35.1594 84.9399 34.4656 85.6259C33.7719 86.3119 32.9502 86.8499 31.987 87.24C31.0238 87.6233 29.9663 87.8184 28.8146 87.8184C27.683 87.8184 26.639 87.6233 25.6758 87.24C24.7126 86.8567 23.8842 86.3186 23.1971 85.6259C22.5101 84.9332 21.9713 84.1195 21.5806 83.1846C21.1899 82.2431 21.0013 81.2209 21.0013 80.1112C21.0013 79.0015 21.1967 77.9793 21.5806 77.0378C21.9645 76.0962 22.5034 75.2825 23.1971 74.5965C23.8909 73.9038 24.7126 73.3725 25.6758 72.9824C26.639 72.5991 27.683 72.4041 28.8146 72.4041C29.9663 72.4041 31.0238 72.5991 31.987 72.9824C32.9502 73.3725 33.7786 73.9038 34.4656 74.5965C35.1527 75.2825 35.6915 76.1029 36.0822 77.0378C36.4661 77.9793 36.6614 79.0015 36.6614 80.1112ZM34.3983 80.1112C34.3983 79.2974 34.2703 78.5308 34.0076 77.8112C33.745 77.0916 33.3745 76.4661 32.8963 75.9281C32.4181 75.3968 31.8321 74.9731 31.1383 74.657C30.4513 74.3409 29.6767 74.1862 28.8146 74.1862C27.9591 74.1862 27.1846 74.3409 26.511 74.657C25.8307 74.9731 25.2515 75.3901 24.7665 75.9281C24.2883 76.4594 23.9111 77.0848 23.6551 77.8112C23.3925 78.5308 23.2645 79.2974 23.2645 80.1112C23.2645 80.925 23.3925 81.6917 23.6551 82.4113C23.9178 83.1309 24.2883 83.7563 24.7665 84.2943C25.2447 84.8256 25.8307 85.2426 26.511 85.5452C27.1913 85.8479 27.9591 86.0025 28.8146 86.0025C29.67 86.0025 30.4445 85.8479 31.1383 85.5452C31.8321 85.2426 32.4113 84.8256 32.8963 84.2943C33.3745 83.763 33.7517 83.1376 34.0076 82.4113C34.2703 81.6917 34.3983 80.925 34.3983 80.1112Z"
                        fill="currentColor"
                    />
                    <path
                        d="M53.4261 80.1112C53.4261 81.2209 53.2308 82.2431 52.8469 83.1846C52.4562 84.1262 51.9241 84.9399 51.2303 85.6259C50.5366 86.3119 49.7149 86.8499 48.7517 87.24C47.7885 87.6233 46.731 87.8184 45.5793 87.8184C44.4477 87.8184 43.4037 87.6233 42.4405 87.24C41.4773 86.8567 40.6489 86.3186 39.9618 85.6259C39.2748 84.9332 38.736 84.1195 38.3453 83.1846C37.9547 82.2431 37.7661 81.2209 37.7661 80.1112C37.7661 79.0015 37.9614 77.9793 38.3453 77.0378C38.7292 76.0962 39.2681 75.2825 39.9618 74.5965C40.6556 73.9038 41.4773 73.3725 42.4405 72.9824C43.4037 72.5991 44.4477 72.4041 45.5793 72.4041C46.731 72.4041 47.7885 72.5991 48.7517 72.9824C49.7149 73.3725 50.5433 73.9038 51.2303 74.5965C51.9174 75.2825 52.4562 76.1029 52.8469 77.0378C53.2308 77.9793 53.4261 79.0015 53.4261 80.1112ZM51.163 80.1112C51.163 79.2974 51.035 78.5308 50.7723 77.8112C50.5096 77.0916 50.1392 76.4661 49.661 75.9281C49.1828 75.3968 48.5968 74.9731 47.903 74.657C47.216 74.3409 46.4414 74.1862 45.5793 74.1862C44.7238 74.1862 43.9493 74.3409 43.2757 74.657C42.5954 74.9731 42.0162 75.3901 41.5312 75.9281C41.053 76.4594 40.6758 77.0848 40.4198 77.8112C40.1572 78.5308 40.0292 79.2974 40.0292 80.1112C40.0292 80.925 40.1572 81.6917 40.4198 82.4113C40.6825 83.1309 41.053 83.7563 41.5312 84.2943C42.0094 84.8256 42.5954 85.2426 43.2757 85.5452C43.956 85.8479 44.7238 86.0025 45.5793 86.0025C46.4347 86.0025 47.2093 85.8479 47.903 85.5452C48.5968 85.2426 49.176 84.8256 49.661 84.2943C50.1392 83.763 50.5164 83.1376 50.7723 82.4113C51.035 81.6917 51.163 80.925 51.163 80.1112Z"
                        fill="currentColor"
                    />
                    <path
                        d="M67.6785 84.9669C67.0521 85.9084 66.2371 86.6145 65.2335 87.0987C64.2299 87.5762 63.1926 87.8184 62.1284 87.8184C60.9767 87.8184 59.9394 87.6166 59.0031 87.2198C58.0736 86.823 57.2788 86.2783 56.6188 85.5923C55.9587 84.9063 55.4468 84.0858 55.0831 83.151C54.7193 82.2095 54.5308 81.2007 54.5308 80.1112C54.5308 79.0284 54.7126 78.0129 55.0831 77.0714C55.4468 76.1299 55.9587 75.3161 56.6188 74.6301C57.2788 73.9442 58.0736 73.3994 59.0031 73.0026C59.9326 72.6058 60.9767 72.4041 62.1284 72.4041C63.1926 72.4041 64.2299 72.6529 65.2335 73.1371C66.2371 73.6281 67.0521 74.3544 67.6785 75.3161H67.7391V63.7554H69.8743V87.4417H67.7391V84.9669H67.6785ZM56.7872 80.1112C56.7872 80.925 56.9151 81.6917 57.1644 82.4113C57.4136 83.1309 57.7773 83.7563 58.2488 84.2943C58.7203 84.8256 59.2995 85.2426 59.9933 85.5452C60.687 85.8479 61.4683 86.0025 62.344 86.0025C63.159 86.0025 63.9133 85.8479 64.6004 85.5452C65.2874 85.2426 65.8868 84.8323 66.392 84.3078C66.8972 83.7832 67.2878 83.1645 67.5707 82.4449C67.8536 81.7253 67.9951 80.9451 67.9951 80.1112C67.9951 79.2773 67.8536 78.4972 67.5707 77.7776C67.2878 77.058 66.8972 76.4325 66.392 75.8945C65.8868 75.3632 65.2941 74.9462 64.6004 74.6436C63.9066 74.3409 63.159 74.1862 62.344 74.1862C61.4683 74.1862 60.6803 74.3409 59.9933 74.657C59.2995 74.9731 58.7203 75.3901 58.2488 75.9281C57.7773 76.4594 57.4203 77.0848 57.1644 77.8112C56.9151 78.524 56.7872 79.2907 56.7872 80.1112Z"
                        fill="currentColor"
                    />
                </g>
                <!-- ads -->
                <mask :id="'mask0_929_9' + id" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="71" y="63" width="49" height="25">
                    <rect x="71" y="63" width="49" height="25" fill="#D9D9D9" />
                </mask>
                <g :mask="'url(#mask0_929_9' + id + ')'" ref="ads">
                    <path
                        d="M74.185 87.4417H71.7064L81.3112 65.2551H83.507L93.0445 87.4417H90.5321L88.0871 81.611H76.6031L74.185 87.4417ZM77.445 79.5463H87.2385L82.3417 67.7636L77.445 79.5463Z"
                        fill="currentColor"
                    />
                    <path
                        d="M105.33 84.9669C104.704 85.9084 103.889 86.6145 102.885 87.0987C101.881 87.5762 100.844 87.8184 99.78 87.8184C98.6282 87.8184 97.5909 87.6166 96.6547 87.2198C95.7252 86.823 94.9304 86.2783 94.2703 85.5923C93.6103 84.9063 93.0984 84.0858 92.7346 83.151C92.3709 82.2095 92.1823 81.2007 92.1823 80.1112C92.1823 79.0284 92.3642 78.0129 92.7346 77.0714C93.0984 76.1299 93.6103 75.3161 94.2703 74.6301C94.9304 73.9442 95.7252 73.3994 96.6547 73.0026C97.5842 72.6058 98.6282 72.4041 99.78 72.4041C100.844 72.4041 101.881 72.6529 102.885 73.1371C103.889 73.6281 104.704 74.3544 105.33 75.3161H105.391V63.7554H107.526V87.4417H105.391V84.9669H105.33ZM94.4455 80.1112C94.4455 80.925 94.5734 81.6917 94.8226 82.4113C95.0719 83.1309 95.4356 83.7563 95.9071 84.2943C96.3786 84.8256 96.9578 85.2426 97.6516 85.5452C98.3453 85.8479 99.1266 86.0025 100.002 86.0025C100.817 86.0025 101.572 85.8479 102.259 85.5452C102.946 85.2426 103.545 84.8323 104.05 84.3078C104.555 83.7832 104.946 83.1645 105.229 82.4449C105.512 81.7253 105.653 80.9451 105.653 80.1112C105.653 79.2773 105.512 78.4972 105.229 77.7776C104.946 77.058 104.555 76.4325 104.05 75.8945C103.545 75.3565 102.952 74.9462 102.259 74.6436C101.565 74.3409 100.817 74.1862 100.002 74.1862C99.1266 74.1862 98.3386 74.3409 97.6516 74.657C96.9578 74.9731 96.3786 75.3901 95.9071 75.9281C95.4356 76.4594 95.0786 77.0848 94.8226 77.8112C94.5667 78.524 94.4455 79.2907 94.4455 80.1112Z"
                        fill="currentColor"
                    />
                    <path
                        d="M118.121 76.2576C117.804 75.6524 117.333 75.1547 116.693 74.7713C116.053 74.388 115.326 74.193 114.511 74.193C114.133 74.193 113.756 74.2333 113.379 74.3208C113.002 74.4015 112.665 74.5427 112.375 74.731C112.079 74.9193 111.85 75.1614 111.668 75.4506C111.493 75.7398 111.399 76.0895 111.399 76.4863C111.399 77.1723 111.682 77.7036 112.247 78.0667C112.813 78.4299 113.702 78.7393 114.915 78.9881C116.652 79.3445 117.932 79.8624 118.761 80.5416C119.589 81.2209 120 82.1355 120 83.2855C120 84.1195 119.838 84.8256 119.515 85.404C119.192 85.9756 118.767 86.4464 118.242 86.8163C117.717 87.1795 117.131 87.4417 116.471 87.6031C115.811 87.7578 115.15 87.8385 114.477 87.8385C113.366 87.8385 112.288 87.6099 111.244 87.1526C110.2 86.6952 109.338 85.9756 108.671 84.9937L110.368 83.7429C110.745 84.3885 111.298 84.9332 112.032 85.3704C112.766 85.8075 113.568 86.0294 114.45 86.0294C114.908 86.0294 115.353 85.9891 115.77 85.9016C116.188 85.8209 116.551 85.673 116.868 85.4645C117.185 85.256 117.434 84.987 117.622 84.6642C117.811 84.3414 117.905 83.9379 117.905 83.4604C117.905 82.687 117.535 82.1086 116.794 81.7185C116.053 81.3352 114.975 80.9721 113.574 80.6358C113.137 80.5349 112.672 80.4004 112.18 80.2457C111.688 80.091 111.23 79.8624 110.813 79.5597C110.395 79.2571 110.052 78.8738 109.776 78.4165C109.506 77.9591 109.365 77.3808 109.365 76.6948C109.365 75.9416 109.513 75.3026 109.802 74.7646C110.092 74.2333 110.489 73.7962 110.981 73.4465C111.473 73.1035 112.032 72.8479 112.658 72.6798C113.285 72.5117 113.931 72.431 114.605 72.431C115.629 72.431 116.626 72.6596 117.589 73.117C118.552 73.5743 119.273 74.2266 119.751 75.0605L118.121 76.2576Z"
                        fill="currentColor"
                    />
                </g>
            </svg>
        </span>
    </div>
</template>

<script>
export default {
    props: ["id", "color", "background"],
    methods: {
        start() {
            return this.start_animation.play(0);
        },
        change() {
            if (!this.change_yoyo_animation) {
                const { timeline } = this.$gsap;
                let reference = this.createChangeAnimation().reverse(1);
                this.change_yoyo_animation = timeline()
                    //
                    .add(reference)
                    .repeatDelay(0.75)
                    .yoyo(true)
                    .repeat(1);
            }
            return this.change_yoyo_animation.play(0);
        },
        show() {
            return this.change_animation.play(0);
        },
        hide() {
            return this.change_animation.reverse(1);
        },
        createChangeAnimation() {
            const { timeline } = this.$gsap;

            let logo = this.$refs.logo;
            let good = this.$refs.good;
            let ads = this.$refs.ads;
            let background = this.$refs.background;

            return timeline()
                .fromTo(background, { width: "0%", paddingBottom: "0%" }, { width: "100%", paddingBottom: "100%", duration: 0.875, ease: "none" })
                .from(logo, { duration: 1.5, r: 0 }, "<=0%")
                .from(good, { duration: 1, y: 35 }, "<=25%")
                .from(ads, { duration: 1, y: 35 }, "<=50%")
                .progress(1);
        },
    },
    mounted() {
        const { timeline } = this.$gsap;

        let logo = this.$refs.logo;
        let good = this.$refs.good;
        let ads = this.$refs.ads;
        let background = this.$refs.background;
        let circle1 = this.$refs.circle1;
        let circle2 = this.$refs.circle2;
        let circle3 = this.$refs.circle3;

        let hypot = Math.hypot(innerWidth, innerHeight);

        this.start_animation = timeline()
            .set(background, {
                width: 0,
                paddingBottom: 0,
            })
            .set(circle1, {
                left: "50%",
                top: "34%",
                x: "-50%",
                y: "-50%",
                width: hypot,
                height: hypot,
            })
            .set(circle2, {
                left: "50%",
                top: "34%",
                x: "-50%",
                y: "-50%",
                width: hypot,
                height: hypot,
            })
            .set(circle3, {
                left: "50%",
                top: "34%",
                x: "-50%",
                y: "-50%",
                width: hypot,
                height: hypot,
            })
            .fromTo(circle3, { scale: 2 }, { scale: 0, duration: 14 / 8 }, 0)
            .fromTo(circle2, { scale: 2.25 }, { scale: 0, duration: 17 / 8 }, 0)
            .set(background, {
                width: "100%",
                paddingBottom: "100%",
            })
            .fromTo(circle1, { scale: 2.25 }, { scale: 0, duration: 23 / 8 }, 0)
            .from(logo, { duration: 7 / 8, r: 0 }, 17 / 8)
            .from(good, { duration: 7 / 8, y: 35 }, "<=50%")
            .from(ads, { duration: 7 / 8, y: 35 }, "<=50%")
            .progress(1);

        this.change_animation = this.createChangeAnimation();
    },
};
</script>

<style lang="scss">
.logo {
    position: relative;
    display: block;
    &:not(:last-child) {
        position: absolute;
    }
    &__wrapper {
        position: relative;
        z-index: 1;
        display: block;
    }
    &__background {
        position: absolute;
        width: 100%;
        padding-bottom: 100%;
        top: 34%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) scale(2.08);
        border-radius: 50%;
        display: inline-block;
    }
    &__element {
        position: relative;
        z-index: 2;
        width: 7.5rem;
        height: auto;
    }
    &__circle {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: translate(-50%, -50%);

        border-radius: 50%;
        z-index: -1;

        pointer-events: none;
    }
    @media (max-width: 979px) {
        &__background {
            transform: translate3d(-50%, -50%, 0) scale(1.75);
        }
        &__element {
            width: 3.33rem;
        }
    }
}
</style>

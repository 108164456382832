<template>
    <works-controls :data="works" ref="controls" @onUpdatePage="updatePage" @onUpdateTags="updateTags"></works-controls>
    <works-list :data="filtered_works" class="works"></works-list>
    <!-- <div class="works-list">
        <router-link v-for="(item, index) in filtered_works" :to="$route.path + '/' + item.slug" :key="index">
            <works-card :item="item"></works-card>
        </router-link>
    </div> -->
</template>

<script>
import WorksControls from "./WorksControls.vue";
import WorksList from "./WorksList.vue";
export default {
    data() {
        return {
            page: null,
            tags: [],
        };
    },
    components: { WorksControls, WorksList },
    computed: {
        works() {
            return this.$store.getters.works;
        },
        filtered_works() {
            return this.works
                .filter((p) => {
                    return p.page === this.page;
                })
                .filter((p) => {
                    return p.tags.some((tag) => this.tags.includes(tag));
                });
        },
    },
    methods: {
        updatePage(val) {
            this.page = val;
        },
        updateTags(val) {
            this.tags = val;
        },
    },
    created() {
        if (!this.$store.getters.works.length) this.$store.dispatch("fetch_works_raw");
    },
};
</script>

<style lang="scss" scoped>
.works {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 1rem 0;
}

@media (max-width: 979px) {
    .works {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
</style>

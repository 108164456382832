<template>
    <div class="work-detail" v-if="work">
        <div class="work-detail-top">
            <h2>{{ work.title }}</h2>
            <p>{{ work.description }}</p>
        </div>
        <div class="work-detail-main">
            <div class="work-detail-sidebar">
                <div class="work-detail-logo" v-if="work.logo">
                    <img :src="work.logo" :alt="work.logo_alt || 'project logo image'" />
                </div>
                <div class="work-detail-tags" v-if="work.tags.length">
                    <template v-for="(tag, index) in work.tags" :key="tag">
                        <span>{{ tag }}</span>
                        {{ index !== work.tags.length - 1 ? " | " : "" }}
                    </template>
                </div>
                <div class="work-detail-short-description" v-if="work.short_description">{{ work.short_description }}</div>
                <div v-if="work.term !== null">
                    Задача выполнена за:
                    <div class="work-detail-term">
                        <i>
                            <span v-for="ch in work.term.toString()" :key="ch"> {{ ch }} </span>
                        </i>
                        дней
                    </div>
                </div>
                <div v-if="work.link">
                    Ссылка на проект:
                    <div class="work-detail-link">
                        <a href="">{{ work.link }}</a>
                    </div>
                </div>
            </div>
            <div class="work-detail-content" ref="content">
                <div v-html="work.html_content"></div>
                <section class="work-detail-content-tags" v-if="work.tags.length">
                    <template v-for="(tag, index) in work.tags" :key="tag">
                        <span>{{ tag }}</span>
                    </template>
                </section>
                <section v-if="works.filter((w) => w.slug !== work.slug).length">
                    <div>
                        <works-list :data="works.filter((w) => w.slug !== work.slug)" class="works"></works-list>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import WorksList from "./WorksList.vue";

export default {
    components: { WorksList },
    computed: {
        works() {
            return this.$store.getters.works;
        },
        work() {
            return this.works.filter((w) => w.slug === this.$route.params.work_id)[0];
        },
    },
    created() {
        if (!this.$store.getters.works.length) this.$store.dispatch("fetch_works_raw");
        console.log(this.$route);
    },
};
</script>

<style lang="scss">
.work-detail {
    margin-top: 1rem;
    margin-bottom: 1.25rem;
    display: grid;
    grid-gap: 1rem;
}
.work-detail-top {
    h2 {
        font-weight: 600;
        font-size: 1.25rem;
        color: var(--color-purple);
        & + * {
            margin-top: 1em;
        }
    }
    p {
    }
}
.work-detail-main {
    display: grid;
    grid-gap: 1rem;
}
.work-detail-sidebar {
    font-size: 0.7rem;
    & > * {
        border-top: 1px dashed rgba($color: #000000, $alpha: 0.1);
        padding-top: 1em;
        padding-bottom: 1em;
    }
}
.work-detail-logo {
    img,
    svg {
        max-width: 8em;
        height: auto;
        width: 100%;
    }
}
.work-detail-tags {
    display: block !important;
    color: rgba($color: #000000, $alpha: 0.1);
    span {
        color: #000000;
    }
}
.work-detail-term {
    margin-top: 0.35em;
    i {
        display: inline-flex;
        background: var(--color-purple);
        color: #fff;
        border-radius: 0.3em;
        padding: 0.2em 0;

        span {
            min-width: 1em;
            padding: 0em 0.1em;
            text-align: center;
            &:not(:last-child) {
                margin-right: -1px;
                border-right: 1px solid #000;
            }
        }
    }
}
.work-detail-link {
    a {
        font-weight: 600;
        color: var(--color-purple);
    }
}
.work-detail-content {
    scroll-behavior: smooth;

    & > div {
        display: flex;
        flex-wrap: wrap;
        align-items: start;
        gap: 1rem;
        & > * {
            min-width: 0;
        }
    }
    & > div > * {
        flex: 1;
        flex-basis: 100%;
    }
    .image {
        display: grid;
        background: #d9d9d9;
        overflow: hidden;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 0.7rem 0px rgba(0, 0, 0, 0.07);

        figcaption {
            order: -1;
            margin: max(2px, 0.15em) auto;
            padding: max(2px, 0.15em) 2em;
            width: 8rem;
            text-align: center;

            background: #f3f3f3;
            border-radius: 1em;
            font-size: 0.8rem;
        }
    }
    img,
    video,
    iframe {
        width: 100%;
    }
    iframe {
        aspect-ratio: 2/1;
        height: auto;
    }
    hr {
        border: 0px solid transparent;
        border-top: 1px solid #e1e1e1;
    }

    & > section {
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid #adadad88;
        padding-bottom: 0.25rem;
    }
}
.work-detail-layout {
    border-radius: 0.625rem;
    overflow: hidden;
    position: relative;

    box-shadow: 0px 0px 0.7rem 0px rgba(0, 0, 0, 0.07);

    flex-basis: 100%;

    img {
        display: block;
        width: 100%;
    }

    &::before {
        content: "";
        display: block;
        border-top: 1.875rem solid #d9d9d9;
    }
    &::after {
        content: attr(data-link);
        position: absolute;
        display: block;
        max-width: 21rem;
        width: calc(100% - 2rem);
        background: #f3f3f3;
        top: 0.25rem;
        height: 1.375rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        text-align: center;
        border-radius: 0.25rem;

        font-size: 0.725rem;
        line-height: 1.375rem;
    }
}

.work-detail-content-tags {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    gap: 1em;
    & > * {
        width: fit-content;
        display: grid;
        place-items: center;
        padding: 0 1rem;
        padding-top: 1px;
        height: 2.4em;
        text-transform: uppercase;
        border-radius: 0.35em;
        border: 1px solid #adadad;
        position: relative;
    }
}

@media (min-width: 980px) {
    .work-detail-sidebar {
        font-size: 1rem;
    }
    .work-detail-main {
        display: grid;
        grid-template-columns: 17.5rem auto;
        grid-gap: 8.75rem;
    }
    .work-detail-sidebar {
        width: 17.5rem;
        font-size: 1rem;
    }
    .work-detail-content {
        grid-column: 2/3;
        max-height: calc(100vh - var(--footer-height) - 32rem);
        height: 100%;
        overflow: auto;

        [style*="width:50%"] {
            flex-basis: calc((100% - 1rem) / 2);
        }
    }
    .work-detail-top {
        h2 {
            font-size: 2.8rem;
        }
        p {
            font-size: 1.875rem;
        }
    }
}
</style>

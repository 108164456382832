<template>
    <div ref="animationRef">
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: ["from", "to", "fromTo", "progress"],
    mounted() {
        let targetElement = this.$refs.animationRef;
        if (this.progress != null) {
            if (this.from) {
                let { from } = this.$gsap;
                this.animationInst = from(targetElement, this.from);
            }
            if (this.to) {
                let { to } = this.$gsap;
                this.animationInst = to(targetElement, this.to);
            }
            if (this.fromTo) {
                let { fromTo } = this.$gsap;
                this.animationInst = fromTo(targetElement, this.fromTo);
            }
            this.animationInst.pause().progress(0);
        }
    },
    updated() {
        if (this.progress && this.animationInst) {
            this.animationInst.pause().progress(this.progress);
        }
    },
    unmounted() {
        // this.animationInst.kill && this.animationInst.kill();
        // this.animationInst = null;
    },
};
</script>

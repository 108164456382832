<template>
    <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20.8433" cy="21.5" r="20.3433" stroke="currentColor" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.3566 15.5427C32.5244 14.9718 32.3566 14.5522 31.5582 14.5522H28.9179C28.2465 14.5522 27.937 14.9146 27.7692 15.3143C27.7692 15.3143 26.4265 18.6541 24.5244 20.8236C23.909 21.4515 23.6293 21.6513 23.2936 21.6513C23.1258 21.6513 22.874 21.4515 22.874 20.8807V15.5427C22.874 14.8576 22.688 14.5522 22.1285 14.5522H17.9795C17.5599 14.5522 17.3076 14.8702 17.3076 15.1716C17.3076 15.821 18.2586 15.9708 18.3567 17.7978V21.7656C18.3567 22.6355 18.2027 22.7932 17.867 22.7932C16.972 22.7932 14.7947 19.4385 13.5034 15.5997C13.2503 14.8536 12.9965 14.5522 12.3217 14.5522H9.68138C8.92701 14.5522 8.77612 14.9146 8.77612 15.3143C8.77612 16.0279 9.67125 19.5676 12.944 24.249C15.1258 27.4461 18.1998 29.1791 20.997 29.1791C22.6753 29.1791 22.8829 28.7942 22.8829 28.1312V25.7149C22.8829 24.945 23.0419 24.7914 23.5734 24.7914C23.965 24.7914 24.6363 24.9912 26.2028 26.5326C27.993 28.3595 28.2881 29.1791 29.2951 29.1791H31.9354C32.6898 29.1791 33.0669 28.7942 32.8493 28.0346C32.6112 27.2775 31.7565 26.1791 30.6224 24.877C30.0069 24.1348 29.0839 23.3356 28.8041 22.9359C28.4125 22.4221 28.5244 22.1937 28.8041 21.737C28.8041 21.737 32.021 17.1126 32.3566 15.5427Z"
            fill="currentColor"
        />
    </svg>
</template>

<template>
    <!-- {{ item }} -->
    <template v-if="item">
        <div class="work">
            <div class="work-image">
                <img :src="item.images[0]" alt="" />
            </div>
            <div class="work-title">{{ item.title }}</div>
            <ul class="work-tags">
                <li v-for="tag in item.tags" :key="tag">{{ tag }}</li>
            </ul>
        </div>
    </template>
    <template v-else>
        <div class="work-placeholder placeholder">
            <div class="work-image placeholder"></div>
            <div class="work-title-placeholder">
                <div class="placeholder" style="width: 5em"></div>
                <div class="placeholder" style="width: 4em"></div>
                <div class="placeholder" style="width: 3em"></div>
            </div>
            <ul class="work-tags-placeholder">
                <div class="placeholder" style="width: 3em"></div>
                <div class="placeholder" style="width: 4em"></div>
                <div class="placeholder" style="width: 2em"></div>
                <div class="placeholder" style="width: 2em"></div>
                <div class="placeholder" style="width: 4em"></div>
            </ul>
        </div>
    </template>
</template>

<script>
export default {
    props: ["item"],
};
</script>

<style lang="scss" scoped>
.work {
    padding: 1rem;

    position: relative;
    z-index: 2;
    transition: 0.25s;
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        box-shadow: 0px 0px 10px 0px #0000000d;
        border-radius: 0.75rem;
        background: #fff;

        transition: inherit;
    }

    &:hover {
        &::before {
            box-shadow: 0px 0px 15px 0px #0000001f;
            margin: -0.35rem;
        }
    }
}
.work-image {
    aspect-ratio: 1/1;
    width: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.work-title {
    transition: inherit;
    font-size: 1.55rem;
    font-weight: 700;
    margin: 2rem 0;
    .work:hover & {
        color: var(--color-purple);
    }
}
.work-tags {
    padding-top: 1rem;
    border-top: 1px dashed #d9d9d9;

    font-size: 1rem;

    & > * {
        display: inline;
        &:not(:last-child) {
            &::after {
                content: "|";
                color: #d9d9d9;
                letter-spacing: 500%;
                padding: 0 0.5em;
            }
        }
    }
}

.work-placeholder {
    padding: 1rem;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-radius: 0.75rem;
    animation: none;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.025);
}
.work-title-placeholder {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3em 0.5em;
    font-size: 1.55rem;
    margin: 2rem 0;
}
.work-tags-placeholder {
    padding-top: 1rem;
    border-top: 1px dashed #d9d9d9;
    display: flex;
    flex-wrap: wrap;
    gap: 0.3em 0.5em;
}

@media (max-width: 979px) {
    .work {
        padding: 0.75rem;
    }
    .work-title {
        font-size: 1rem;
        margin: 1rem 0;
    }
    .work-tags {
        padding-top: 0.75rem;
        font-size: 0.75rem;
    }
}
</style>
